import { useRef } from 'react';
import { usePlayer, usePlayerUpdates } from 'kaltura-player';
import { Icon } from 'components/Icons/Icon';
import { rewindButton, rewinding } from './ControlBar.module.scss';
import { gv_analytics } from 'utils/Analytics';

export const ControlRewind = ({ playerId, size }) => {
	const { getPlayerTime } = usePlayerUpdates(playerId);
	const { playerSeek } = usePlayer(playerId);
	const icon = useRef();
	const rewind = () => {
		if (!playerSeek) return;
		const rewindSeconds = 7;
		playerSeek({
			seekTo: getPlayerTime() - rewindSeconds * 1000,
			pause: false,
		});
		icon.current?.classList?.add(rewinding);
		setTimeout(() => icon.current?.classList?.remove(rewinding), 200);
		gv_analytics.capture('USER_REWIND');
	};

	return (
		<Icon
			ref={icon}
			className={rewindButton}
			name="rewind"
			size={size || 35}
			onClick={rewind}
			data-name="ControlRewind"
		/>
	);
};
