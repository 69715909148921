import { HTMLAttributes, ReactNode } from 'react';
import styles from './Card.module.scss';

interface CardProps extends HTMLAttributes<HTMLElement> {
	name?: string;
	header?: ReactNode;
}
export const Card = ({
	id,
	className,
	header = <></>,
	children,
	...props
}: CardProps) => (
	<article
		// If you want to use your own className / style, be sure to import and @extend the .Card class.
		// This is necessary to avoid conflicts with rule specificity
		id={id}
		className={className ? className : styles.Card}
		{...props}
	>
		{header && <header className={styles.Header}>{header}</header>}
		{children}
	</article>
);
