import React, {
	forwardRef,
	HTMLAttributes,
	InputHTMLAttributes,
	ReactNode,
	SelectHTMLAttributes,
} from 'react';
import { cls } from '../../utils/cls';
import styles from './Form.module.scss';

interface FormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	className?: string;
	error?: boolean;
	errorText?: string;
	loading?: boolean;
	onChange?: any;
}
export const FormField = forwardRef<HTMLInputElement, FormFieldProps>(
	(
		{ className, error, errorText, label, loading, onChange, ...props },
		ref
	) => (
		<label
			className={cls(
				styles.FormField,
				error && styles.FieldError,
				loading && styles.FieldLoading,
				className
			)}
			aria-label={`${props.name}_label`}
			data-name={`${props.name}_label`}
		>
			<span className={styles.Label}>{label}</span>
			<input
				ref={ref}
				aria-label={props.name}
				data-name={props.name}
				className={styles.Input}
				onChange={onChange}
				{...props}
			/>
			{error && (
				<legend
					className={styles.ErrorText}
					aria-label={`${props.name}_error`}
					data-name={`${props.name}_error`}
				>
					{errorText}
				</legend>
			)}
		</label>
	)
);

type FormPhoneFieldProps = Omit<FormFieldProps, 'type'>;
export const FormPhoneField = forwardRef<HTMLInputElement, FormPhoneFieldProps>(
	(props, ref) => (
		<FormField ref={ref} type="tel" autoComplete="tel" {...props} />
	)
);

interface FormCheckableProps
	extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
	label: string;
	className?: string;
	error?: boolean;
}
export const FormRadio = forwardRef<HTMLInputElement, FormCheckableProps>(
	({ className, error, label, ...props }, ref) => (
		<label
			className={cls(
				styles.FormRadio,
				error && styles.FieldError,
				className
			)}
			data-name={`${props.name}-${props.value}`}
		>
			<input ref={ref} className={styles.Input} type="radio" {...props} />
			<span className={styles.Label}>{label}</span>
		</label>
	)
);

export const FormCheckBox = forwardRef<HTMLInputElement, FormCheckableProps>(
	({ className, error, label, ...props }, ref) => (
		<label
			className={cls(
				styles.FormCheckBox,
				error && styles.FieldError,
				className
			)}
			data-name={props.name}
		>
			<input
				ref={ref}
				className={styles.Input}
				type="checkbox"
				{...props}
			/>
			<span className={styles.Label}>{label}</span>
		</label>
	)
);

export interface OptionProps {
	label: ReactNode;
	value: string | number;
}
const Option = ({ value, label }: OptionProps) => (
	<option value={value}>{label}</option>
);

interface OptionGroupProps {
	label: string;
	options: OptionProps[];
}
const OptionGroup = ({ label, options }: OptionGroupProps) => (
	<optgroup label={label}>
		{options.map((option) => (
			<Option key={option.value} {...option} />
		))}
	</optgroup>
);

interface FormSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
	label: string;
	options: OptionProps[] | Record<string, OptionProps[]>;
	className?: string;
	error?: boolean;
}
export const FormSelect = forwardRef<HTMLSelectElement, FormSelectProps>(
	({ children, className, label, options, error, ...props }, ref) => (
		<label
			className={cls(
				styles.FormSelect,
				error && styles.FieldError,
				className
			)}
			data-name={props.name}
		>
			<span className={styles.Label}>{label}</span>
			<select ref={ref} {...props}>
				{Array.isArray(options)
					? options.map((option) => (
							<Option key={option.value} {...option} />
					  ))
					: Object.entries(options).map(([group, subOptions]) => (
							<OptionGroup
								key={group}
								label={group}
								options={subOptions}
							/>
					  ))}
			</select>
		</label>
	)
);

type FormProps = HTMLAttributes<HTMLFormElement>;
export const Form = ({ children, className, ...props }: FormProps) => {
	return (
		<form className={cls(styles.Form, className)} {...props}>
			{children}
		</form>
	);
};
