import { VoteButton } from "./VoteButton";
import FadeIn from "utils/FadeIn"
export function createVoteButtons (elements, curVote, setCurVote, delay=0, flexOrder=null) {
   delay = parseInt(delay);
    return elements
       .map((answerText, index) => {
       
       // Unless specified, set flex Order to the index of the element in the array of answers.
       if (flexOrder === null) flexOrder = index;
 
       return answerText?.trim() === "" 
       ? null // Return null, filter it out later, but importantly, we keep the index increasing so we stay in sync with the backend
       : <FadeIn key={index+":"+answerText} style={{order: flexOrder}} delayInSeconds={delay+index*0.1}>
       <VoteButton
       data-name={VoteButton+index}
       key={index+":"+answerText}
       dataVote={index}
       currentVote={curVote}
       updater={setCurVote}
       >
           {answerText}
         </VoteButton>
       </FadeIn>
     })
     .filter((x) => x)
 }
 