import { RefCallback, useCallback, useEffect, useRef } from 'react';
import intlTelInput, { Plugin } from 'intl-tel-input';
import * as Sentry from '@sentry/react';
import 'intl-tel-input/build/css/intlTelInput.css';

const defaultApiUrl = process.env.REACT_APP_API_URL;
const defaultUtilsScript =
	'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js';
const defaultPreferredCountries = ['fr', 'es', 'pt', 'it', 'de', 'gb', 'us'];
const defaultGeoIpLookup = async (success: (countryCode: string) => void) => {
	await fetch('https://ipinfo.io?token=960321ccf4da33')
		.then(async (response) => {
			const { country } = await response.json();
			if (country) success(country);
		})
		.catch((e) => {
			console.error('Could not get country automatically:', e);
			Sentry.captureMessage('ERROR_PERFORMING_IP_LOOKUP');
		});
};

type VerifyphoneResponse = { status: string; error: string };

export const usePhoneIntl = ({
	initialCountry = 'auto',
	geoIpLookup = defaultGeoIpLookup,
	preferredCountries = defaultPreferredCountries,
	utilsScript = defaultUtilsScript,
	apiUrl = defaultApiUrl,
} = {}) => {
	const ref = useRef<HTMLInputElement>();
	const plugin = useRef<Plugin>();

	useEffect(() => {
		if (!ref.current) return;
		plugin.current = intlTelInput(ref.current, {
			initialCountry,
			geoIpLookup,
			preferredCountries,
			utilsScript,
		});

		return () => plugin.current?.destroy();
	}, [initialCountry, geoIpLookup, preferredCountries, utilsScript]);

	const composeRef = useCallback((setRef?: RefCallback<HTMLInputElement>) => {
		return (current: HTMLInputElement) => {
			setRef?.(current);
			ref.current = current;
		};
	}, []);

	const formatCheck = useCallback(() => {
		const error = plugin.current?.getValidationError();

		return (
			!error ||
			Object.entries(
				global.intlTelInputUtils?.validationError || {}
			).reduce(
				(message: boolean | string, [name, code]) =>
					code === error ? name : message,
				''
			)
		);
	}, []);

	const carrierCheck = useCallback(async (phone: string) => {
		const response = await fetch(`/api/VerifyPhone`, {
			body: JSON.stringify({
				phone: plugin.current?.getNumber() || phone,
			}),
			method: 'POST',
		});
		const { status, error } =
			(await response.json()) as VerifyphoneResponse;
		return status === 'OK' || error;
	}, []);

	const validate = useCallback(
		async (phone: string) => {
			// Use built-in validation methods first
			const valid = formatCheck();
			// If local validation works, hit the API to perform a Twilio lookup
			return valid === true ? await carrierCheck(phone) : valid;
		},
		[formatCheck, carrierCheck]
	);

	const setValueAs = useCallback(
		(phone: string) => plugin.current?.getNumber() || phone,
		[]
	);

	return [composeRef, { validate, setValueAs }] as const;
};
