import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { updateLanguage } from './translations/i18n';

import { UiSettingsProvider } from 'hooks/useGlobalSettings';
import { getUiConfig } from './utils/utils';
import { Page, ErrorPage } from 'components';

import './translations/i18n';
import './index.scss';
import { getDefaultLanguage } from 'utils/getDefaultLanguage';
import { posthog } from 'posthog-js';

const IS_PROD = process.env.NODE_ENV === 'production';
if (!IS_PROD)
	console.log(
		'NODE_ENV:',
		process.env.NODE_ENV,
		'ON BRANCH:',
		process.env.BRANCH
	);

if (IS_PROD)
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.NODE_ENV,
		integrations: [
			new posthog.SentryIntegration(
				posthog,
				process.env.SENTRY_ORG,
				process.env.SENTRY_PROJECT_ID
			),
		],
	});

updateLanguage(getDefaultLanguage());
const PlayerPage = lazy(() => import('./pages/Player'));
const SignUpPage = lazy(() => import('./pages/Signup'));

render(
	<React.StrictMode>
		<UiSettingsProvider value={getUiConfig()}>
			<Sentry.ErrorBoundary
				fallback={({ error, resetError }) => {
					return <ErrorPage error={error} resetError={resetError} />;
				}}
			>
				<BrowserRouter>
					<Suspense fallback={<Page />}>
						<Routes>
							<Route
								path="/signup"
								element={
									<SignUpPage
										signupID={new URLSearchParams(
											window.location.search
										)
											.get('signupID')
											// Clean any special characters/gunk that may have gotten in. See: https://sentry.io/organizations/goodvibes/issues/3796014574
											?.replace(/[^a-zA-Z0-9-_: ]/g, '')}
									/>
								}
							/>
							<Route
								path="/embed/*"
								element={<PlayerPage embed={true} />}
							/>
							<Route path="/*" element={<PlayerPage />} />
						</Routes>
					</Suspense>
				</BrowserRouter>
			</Sentry.ErrorBoundary>
		</UiSettingsProvider>
	</React.StrictMode>,
	document.getElementById('react')
);
