import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import isChromatic from 'chromatic/isChromatic';
// import { showReportDialog } from "@sentry/react";

import { CtaButton, Page, PageHeader, PageFooter, Card } from 'components';
import { gvShareMethods, queryStringToObject } from 'utils/utils';
import styles from './ErrorCard.module.scss';
import { updateLanguage } from 'translations/i18n';
import { getDefaultLanguage } from 'utils/getDefaultLanguage';
import removeLoadingOverlay from 'utils/clearAnimation';
import { gv_analytics } from 'utils/Analytics';

/**
 * ErrorPage - Takes default errorBoundary parameters/props provided by Sentry and renders an error page.
 * @param {object}  ErrorObject
 * @paramExample {
 * 	statusCode: {int} 404
 * 	message: "INVALID_LINK"
 * 	cause: trace
 * }
 * @returns
 */
export const ErrorPage = ({ error, resetError }) => {
	updateLanguage(getDefaultLanguage());
	useEffect(removeLoadingOverlay, []); // Clear the loader and fade in the error card after component is mounted.

	return (
		<Page>
			<PageHeader>{/* <h1>{t(card.title)}</h1> */}</PageHeader>
			<ErrorCard error={error} resetError={resetError}></ErrorCard>
			<PageFooter />
		</Page>
	);
};

export const ErrorCard = ({ error, resetError, children }) => {
	const { t } = useTranslation();

	console.log('ErrorCard called with:', typeof error, error);
	const [eventId, setEventId] = useState('');

	const retry_count =
		parseInt(queryStringToObject(window.location.href).retry_count) || 0;

	useEffect(() => {
		try {
			Sentry.setContext({ retry_count });
			Sentry.captureException(error, { setEventId });
			gv_analytics.capture('ERROR_CARD_SHOWN', retry_count);
		} catch (e) {
			console.warn('Could not create Sentry report:', e);
		}
	}, [error, retry_count]);

	useEffect(() => {
		if (eventId)
			console.log('Successfully created issue for eventid:', eventId);
	}, [eventId]);

	const sendEmailReport = () => {
		// showReportDialog() // Not sure why this doesn't work. We'll probably want to build our own form anyway...
		const brand_name = process.env.REACT_APP_BRAND_NAME;
		const email_params = {
			email: 'support@visigo.fr',
			title:
				t(`report_issue_email_subject`, { brand_name }) +
				(eventId ? `| #${eventId}` : ''),
			text: { long: t('report_issue_email_body') },
			code: false,
		};
		let email_href = gvShareMethods.get.email_href(email_params);
		window.open(email_href);
	};

	// set card message defaults
	const card = {
		code: error?.statusCode || 500,
		title: error.name,
		subtitle: '',
		text: error.message,
		cta: [
			{
				label: 'report_issue_cta',
				variant: 'primary',
				fn: sendEmailReport,
			},
		], // Push Optional actions to propose, will be mapped to CtaButtons
	};

	console.log('Evaluating error :', error.name, ':', error.message);

	// Override card defaults with case-specific texts and actions
	switch (error.message) {
		case 'IS_MS_BOT':
			card.code = 403;
			card.title = 'error_is_ms_bot_title';
			card.subtitle = 'error_is_ms_bot_subtitle';
			card.text = 'error_is_ms_bot_text';
			break;
		case 'SIGNUP_ID_NOT_FOUND': // The Signup code provided was not found in the DB
		case 'SIGNUP_ID_MISSING': // User did not provide a link code in URL
		case 'MISSING_LINK_CODE': // User did not provide a link code in URL
		case 'SHORTLINK_NOT_FOUND': // The short code provided was not found in the DB
			card.code = 404;
			card.title = 'not_found_msg_title';
			card.subtitle = 'not_found_msg_subtitle';
			// card.text = error.message || error;
			card.text = 'not_found_msg_text';
			break;
		case 'INVALID_LINK_CODE': // The given shortlink or link code is invalid
		case 'INVALID_CONFIG_PARAMETER_IN_URL': // the config=parameter was not a valid base64-encoded JSON object
			card.code = 400;
			card.title = 'error_card_invalid_url_title';
			card.subtitle = 'error_card_invalid_url_subtitle';
			// card.text = error.message || error;
			card.text = 'error_card_invalid_url_text';
			break;
		case error.message.match(/Loading chunk [\d]+ failed/)?.input: // Brilliant use of regex. 'input' is the original string fed to the regex, returned only if the regex matches. Thus you can test .input against the original string. See: https://stackoverflow.com/a/18881169/13535665
		case 'PLAYER_LOAD_FAILED':
		case 'FAILED_FETCH':
			if (retry_count < 2 && !isChromatic()) {
				// Reloading the page in Chromatic was causing issues
				// window.alert(
				// 	'Reloading due to network error! rc:' + retry_count
				// );
				const redirectUrl = new URL(window.location.href);
				redirectUrl.searchParams.set('retry_count', retry_count + 1);
				return (window.location.href = redirectUrl.toString());
			}
			card.code = 418;
			card.title = 'network_error_title';
			card.subtitle = 'network_error_subtitle';
			card.text = 'network_error_text';
			card.cta.push({
				label: 'reload_page_cta',
				variant: 'primary',
				fn: () => {
					gv_analytics.capture('USER_ATTEMPTED_RELOAD', retry_count);
					window.location.reload();
				},
			});
			break;
		case 'USER_DISABLED': // The link/code was valid, but the referenced user is disabled
		case 'COMPANY_DISABLED': // The link/code was valid, but the referenced company is disabled
		case 'QUOTA_EXCEEDED': // Too much GOOD Vibes!
		default:
			card.code = 500;
			card.title = 'error_card_generic_title';
			card.subtitle = 'error_card_generic_subtitle';
			// card.text = error.message || error;
			card.text = 'error_card_generic_text';
	}

	const { code, title, subtitle, text, cta } = card;
	return (
		<Card id={'ErrorCard'} className={styles.ErrorCard}>
			<div
				className={styles.Content}
				data-name={'ErrorCard'}
				data-error-code={code}
			>
				<h2 className={styles.ErrorCode} data-name="ErrorTitle">
					{t(title)}
				</h2>
				<span className={styles.ErrorSubtitle}>{t(subtitle)}</span>
				<span className={styles.ErrorText}>{t(text)}</span>
				{children}
				{cta.map((cta) => (
					<CtaButton
						key={cta.label}
						data-name={cta.label}
						variant={cta.variant}
						onClick={cta.fn}
					>
						{t(cta.label)}
					</CtaButton>
				))}
			</div>
		</Card>
	);
};
