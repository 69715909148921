import { useEffect, useState } from "react";

export const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const options = { passive: true };
    window.addEventListener("resize", handleResize, options);
    return () => window.removeEventListener("resize", handleResize, options);
  }, []);
  return width;
};
