import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	jsObjectToB64String,
	manageLineBreaks,
	parseUrlFromJson,
} from 'utils/utils';
import { Card, Icon, CtaButton } from 'components';

import styles from './LinkCards.module.scss';
import { useGlobalSettings } from 'hooks/useGlobalSettings';
import { gv_analytics } from 'utils/Analytics';

const categorize = (tiles) =>
	tiles.reduce(
		(categories, { props }) => {
			switch (props.category) {
				case 'Video':
					categories.watch.push(props);
					break;
				case 'Audio':
					categories.listen.push(props);
					break;
				default:
					categories.read.push(props);
			}
			return categories;
		},
		{ read: [], watch: [], listen: [] }
	);

export const ThumbnailTile = ({
	image,
	url,
	file_URL,
	description,
	title,
	type,
	icon,
	provider,
	published,
	modified,
}) => {
	if (file_URL) file_URL = parseUrlFromJson(file_URL);
	// If dates are present, will take the modified date over the published date due to order of OR operation
	let date =
		(modified || published) !== '0001-01-01T00:00:00'
			? modified || published
			: null;
	url = url || file_URL;

	if (!url || url.length < 5) return null;
	else
		return (
			<div
				className={styles.thumbnailTile}
				data-name={'APL_LINK'}
				data-title={title}
				data-provider={provider}
				data-href={url}
				onClick={() => {
					gv_analytics.capture('CLICKED_APL_LINK', {
						title,
						type,
						provider,
						url,
					});
					window.open(url, '_blank');
				}}
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					alt={title}
					src={image}
					referrerPolicy="no-referrer"
					onError={({ target }) => target.remove()}
				/>
				<div className={styles.content}>
					<span className={styles.title}>{title}</span>
					<p className={styles.hide_small}>{description}</p>
					<div className={styles.info}>
						<div className={styles.meta}>
							{provider && (
								<strong className={styles.provider}>
									{icon && (
										<img
											alt={provider}
											src={icon}
											referrerPolicy="no-referrer"
											onError={({ target }) =>
												target.remove()
											}
										/>
									)}{' '}
									{provider}
								</strong>
							)}
							<span className={styles.date}>
								{date &&
									new Date(date).toLocaleDateString(
										undefined,
										{
											year: 'numeric',
											month: 'short',
										}
									)}
							</span>
						</div>
						{type && <span className={styles.type}>{type}</span>}
					</div>
				</div>
				<Icon className={styles.arrow} name="caretRight" />
			</div>
		);
};

export const LinkCard = ({ id, tiles }) => {
	const { read, watch, listen } = categorize(tiles);

	const { t } = useTranslation();

	return (
		<Card id={id} data-name="LinkCard" className={styles.LinkCard}>
			<div className={styles.ListsContainer}>
				{read.length ? (
					<div className={styles.contentList}>
						<div className={styles.listHeader}>
							<p className={styles.label}>
								{t('to_read').toUpperCase()} ({read.length})
							</p>
							<div className={styles.line} />
						</div>
						<div
							className={styles.list}
							data-name={'APL_READ_LIST'}
						>
							{read.map((props, key) => (
								<ThumbnailTile {...props} key={key} />
							))}
						</div>
					</div>
				) : null}
				{watch.length ? (
					<div className={styles.contentList}>
						<div className={styles.listHeader}>
							<p className={styles.label}>
								{t('to_watch').toUpperCase()} ({watch.length})
							</p>
							<div className={styles.line} />
						</div>
						<div
							className={styles.list}
							data-name={'APL_WATCH_LIST'}
						>
							{watch.map((props, key) => (
								<ThumbnailTile {...props} key={key} />
							))}
						</div>
					</div>
				) : null}
				{listen.length ? (
					<div className={styles.contentList}>
						<div className={styles.listHeader}>
							<p className={styles.label}>
								{t('to_listen').toUpperCase()} ({listen.length})
							</p>
							<div className={styles.line} />
						</div>
						<div
							className={styles.list}
							data-name={'APL_LISTEN_LIST'}
						>
							{listen.map((props, key) => (
								<ThumbnailTile {...props} key={key} />
							))}
						</div>
					</div>
				) : null}
			</div>
		</Card>
	);
};

export const EpisodeTile = ({
	id,
	image,
	url,
	file_URL,
	description,
	title,
	videoID,
	language,
	type,
	icon,
	provider,
	published,
	modified,
}) => {
	// If dates are present, will take the modified date over the published date due to order of OR operation
	let date =
		(modified || published) !== '0001-01-01T00:00:00'
			? modified || published
			: null;

	return (
		<div
			id={id}
			className={[styles.thumbnailTile, styles.left_fill_image].join(' ')}
			data-name={'EPISODE_LINK'}
			data-title={title}
			data-video-id={videoID}
			data-provider={provider}
			data-href={url}
			onClick={(e) => {
				gv_analytics.capture('CLICKED_PREV_EPISODE', {
					title,
					videoID,
					language,
				});
				window.open(url, '_blank');
			}}
		>
			<img
				alt={title}
				src={image}
				referrerPolicy="no-referrer"
				onError={({ target }) => target.remove()}
			/>
			<div className={styles.content}>
				<span className={styles.title}>{title}</span>
				<p>{description}</p>
				{/* <div className={styles.info}> */}
				<div className={styles.meta}>
					{provider && (
						<strong className={styles.provider}>
							{icon && (
								<img
									alt={provider}
									src={icon}
									referrerPolicy="no-referrer"
									onError={({ target }) => target.remove()}
								/>
							)}{' '}
							{provider}
						</strong>
					)}
					<span className={styles.date}>
						{date &&
							new Date(date).toLocaleDateString(undefined, {
								year: 'numeric',
								month: 'short',
							})}
					</span>
				</div>
				{/* {type && <span className={styles.type}>{type}</span>}
				</div> */}
			</div>
			<Icon className={styles.arrow} name="caretRight" />
		</div>
	);
};

export const EpisodeCard = ({ id, tiles, back }) => {
	const {
		userData: { userID, companyID },
		video: { videoID, language },
		disable_analytics,
		log_click,
	} = useGlobalSettings();
	let episodes = useMemo(() => {
		// Get existing url parameters, and delete the 'play' in case it's in use,
		// we're going to give each link a new one that corresponds to the correct episode
		let params = new URLSearchParams(window.location.search);
		params.delete('play');
		return tiles
			.filter(
				({ props: { referenceID } }) =>
					referenceID + language !== videoID
			) // filter out the current video from previous videos
			.map(({ props }) => {
				const {
					episode_name,
					episode_hook,
					language,
					plannedDate,
					referenceID,
					player_url_long,
				} = props;

				// build the URL for each episode
				// root path could change
				// don't forget additional parameters like utm or ui config
				const url = `${player_url_long}/c/${jsObjectToB64String({
					companyID,
					userID,
					videoID: referenceID + language,
					disable_analytics: disable_analytics ? 1 : 0, // convert booleans back to integers
					log_click: log_click ? 1 : 0, // convert booleans back to integers
				})}${params.toString().length ? '&' + params : ''}`;
				try {
					return {
						title: manageLineBreaks(episode_name),
						url,
						videoID,
						description: manageLineBreaks(episode_hook),
						language: language,
						published: plannedDate,
						image: props[
							process.env.REACT_APP_APL_PREVIOUS_EPISODE_IMAGE
						],
					};
				} catch (e) {
					console.log('Error processing episode:', e, props);
					return null;
				}
			});
	}, [
		companyID,
		disable_analytics,
		language,
		log_click,
		tiles,
		userID,
		videoID,
	]);

	const { t } = useTranslation();

	return (
		<Card id={id} data-name="EpisodeCard" className={styles.LinkCard}>
			<div className={styles.ListsContainer}>
				{episodes.length ? ( // If the component is rendered, only render the cards if there are episodes in the historial queue.
					<div className={styles.contentList}>
						<div className={styles.listHeader}>
							<p className={styles.label}>
								{t('watch_past_episodes').toUpperCase()} (
								{episodes.length})
							</p>
							<div className={styles.line} />
						</div>
						<div
							className={[styles.list, styles.grid_max_2].join(
								' '
							)}
							data-name={'past_episodes_list'}
						>
							{episodes.map((props, key) => (
								<EpisodeTile {...props} key={key} />
							))}
						</div>
					</div>
				) : (
					// Otherwise, display a message.
					<>
						<h1>{t('no_past_episodes_yet_title')}</h1>
						<span style={{ whiteSpace: 'pre-line' }}>
							{t('no_past_episodes_text')}
						</span>
					</>
				)}
				{back && (
					<CtaButton onClick={back} variant="secondary">
						{t('back')}
					</CtaButton>
				)}
			</div>
		</Card>
	);
};
