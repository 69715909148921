import { useState, useEffect, useRef } from 'react';
import {
	SwitchCard,
	Switched,
	Front,
	Back,
	animationDurationInSeconds,
} from './Switch.module.scss';

/**
 * Switch component
 * Takes an unlimited number of children, and will 'switch' between the first and active child when switching between the primary element (the first child) and the other children.
 * @param {int} active : The index of the child you want to render. 0 Switches back to the primary element, any other number renders that element.
 * Warning: Currently, if you switch directly between two children without going through the primary, the card will switch back to the primary card (element zero), swap it "out of sight", then animate back.
 */
export const Switch = ({ active = 0, children, ...delegated }) => {
	// const [activeFront, setActiveFront] = useState(0);
	const [activeBack, setActiveBack] = useState(1);
	const [lastCard, setLastCard] = useState(1);
	// const [minHeight, setMinHeight] = useState(0);
	const frontEl = useRef(null);
	const backEl = useRef(null);
	// const windowDimensions = useWindowDimensions();
	const [cardIsSwitched, setCardIsSwitched] = useState(false);

	useEffect(() => {
		// console.log("Switch useEffect triggered, active:",active,"acitveBack:",activeBack)
	}, [active, activeBack]);

	// EVALUATE IF INTERMEDIARY CARD IS NEEDED WHEN SWITCHING BETWEEN SECONDARY CARDS
	useEffect(() => {
		if (active === 0) {
			// console.log("Switching back to player")
			setCardIsSwitched(false);
			setTimeout(() => {
				setActiveBack(false);
			}, parseFloat(animationDurationInSeconds) * 1000);
		} else if (lastCard === 0) {
			// Switching from player
			// console.log("Switching directly to new card",active)
			setActiveBack(active);
			setCardIsSwitched(true);
		} else {
			// console.log("Intermediary switch",active,activeBack)
			setCardIsSwitched(false);
			setTimeout(() => {
				setActiveBack(active);
				setCardIsSwitched(true);
			}, parseFloat(animationDurationInSeconds) * 1000);
		}
		setLastCard(active);
		// Exhaustive deps would cause an infinite render loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active]);

	// Update Container size based on rendered children sizes
	// This is a JS-land hack because often the CSS you will use for the child elements (like position: absolute, etc.) will break the parent element being able to inherit height from children.
	// Removed because elements that were larger than 100vh were breaking this, and for some reson min-content is working properly
	// useEffect(() => {
	// 	console.log(56,"called", cardIsSwitched, backEl.current.clientHeight, frontEl.current.clientHeight)
	// 	setMinHeight(
	// 		cardIsSwitched
	// 			? backEl.current.scrollHeight
	// 			: frontEl.current.scrollHeight
	// 	);
	// }, [cardIsSwitched, minHeight, windowDimensions]);

	return (
		<div
			className={SwitchCard}
			{...delegated}
			style={{
				...(delegated.style || {}),
			}}
		>
			<div
				style={{ height: 'min-content' }}
				className={cardIsSwitched ? Switched : null}
			>
				<div ref={frontEl} className={Front}>
					{children[0]}
				</div>
				<div ref={backEl} className={Back}>
					{children[activeBack]}
				</div>
			</div>
		</div>
	);
};

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}
