import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { Card, FormField } from 'components';
import { delay } from '../../utils/delay';
import { useGlobalSettings } from '../../hooks/useGlobalSettings';
import styles from './Preferences.module.scss';
import { CtaButton } from 'components/Common/CtaButton';

interface CheckCodeProps {
	sendCode: () => Promise<any>;
	checkCode: (code: string) => Promise<void>;
	back: () => void;
	destination: string;
}
export const CheckCode = ({
	sendCode,
	checkCode,
	back,
	destination,
}: CheckCodeProps) => {
	const { t } = useTranslation();
	const [disabled, setDisabled] = useState(false);
	const requestSecureLink = async (event: MouseEvent) => {
		event.preventDefault();
		if (disabled) return;
		try {
			setDisabled(true);
			await sendCode();
			toast.success(
				t('request_secure_code_resent'),
				{ duration: 10000 } // 10 seconds
			);
			await delay(30000); // 30 seconds
		} catch (error) {
			await delay(5000); // 5 seconds
		}
		setDisabled(false);
	};

	const {
		userData: { gateway },
	} = useGlobalSettings();
	const isSMS = /SMS/i.test(gateway);
	return (
		<Card id="check" className={styles.CheckCodeCard}>
			<div className={styles.CheckCodeContainer}>
				<h1>
					{isSMS ? t('check_your_messages') : t('check_your_inbox')}
				</h1>
				<p>
					{t('request_secure_code_destination_message', {
						destination,
					})}
				</p>
				<FormField
					label={t('preferences_code_label')}
					placeholder="XXXX"
					data-name={'preferences-code-input'}
					auto-complete="one-time-code"
					onChange={(event: { target: { value: string } }) => {
						// no need of a form here, just check code when it's filled
						if (event.target.value.length === 4)
							checkCode(event.target.value);
					}}
				/>
				<p>{t('having_troubles')}</p>
				<h4>
					<a
						href="#resend"
						onClick={requestSecureLink}
						title={
							disabled
								? t('request_secure_code_cta_disabled')
								: undefined
						}
					>
						{t('resend_code')}
					</a>
				</h4>
				<CtaButton onClick={back} variant={'secondary'}>
					{t('back')}
				</CtaButton>
			</div>
		</Card>
	);
};
