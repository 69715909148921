import { useState } from 'react';
import * as Sentry from '@sentry/react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { checkFetchError } from 'utils/checkFetchError';
import { gv_analytics } from 'utils/Analytics';
import { useGlobalSettings } from 'hooks/useGlobalSettings';
import { CtaButton, CheckCode, RequestCode } from 'components';
import { EditPreferences, FormConfig } from './EditPreferences';

interface PreferencesProps {
	back: () => void;
}

export const Preferences = ({ back }: PreferencesProps) => {
	const [formConfig, setFormConfig] = useState<FormConfig | null>(null);
	const [destination, setDestination] = useState<string | null>(null);
	const [code, setCode] = useState<string | null>(null);

	const { t } = useTranslation();
	const {
		userData: { userID },
		contactEmail: email,
	} = useGlobalSettings();
	const sendCode = async () => {
		try {
			gv_analytics.capture('USER_REQUESTED_SECURE_CODE');
			const { destination } = await fetch(
				'/api/UserPreferencesCodeRequest',
				{
					method: 'POST',
					body: JSON.stringify({ userID }),
				}
			)
				.then(checkFetchError)
				.catch(Sentry.captureException);

			return destination as string;
		} catch (error) {
			gv_analytics.capture('USER_REQUESTING_SECURE_CODE_FAILED');
			toast.error(
				t('request_secure_code_send_fail', { email }),
				{ duration: 10000 } // 10 seconds
			);
			Sentry.captureMessage('USER_REQUESTING_SECURE_CODE_FAILED');
			throw error;
		}
	};

	const checkCodeAndGetFormConfig = async (code: string) => {
		gv_analytics.capture('USER_VERIFYING_PREFERENCES_CODE');
		return await fetch('/api/UserPreferences', {
			method: 'POST',
			body: JSON.stringify({ userID, token: code }),
		})
			.then(checkFetchError)
			.then((formConfig) => {
				setCode(code);
				return formConfig;
			})
			.catch((error) => {
				console.error(error);
				gv_analytics.capture('USER_VERIFYING_PREFERENCES_CODE_FAILED');
				toast.error(
					t('request_secure_code_check_fail', { email, code }),
					{ duration: 10000 } // 10 seconds
				);
				Sentry.captureMessage('FAILED TO CHECK PREFERENCES CODE');
			});
	};

	const updatePreferences = async (values: object) => {
		try {
			const updateResponse = await fetch('/api/UserPreferencesUpdate', {
				method: 'POST',
				body: JSON.stringify({ userID, token: code, ...values }),
			})
				.then(checkFetchError)
				.catch(Sentry.captureException);
			if (updateResponse.message === 'USER_UPDATED')
				toast.success(t('preferences_update_success'));
			else throw new Error('PREFERENCES_UPDATE_FAILED');
			gv_analytics.capture('USER_PREFERENCES_UPDATED');
		} catch (error) {
			toast.error(
				t('preferences_update_failed', { email, code }),
				{ duration: 10000 } // 10 seconds
			);
			Sentry.captureMessage('FAILED TO UPDATE PREFERENCES');
			gv_analytics.capture('USER_PREFERENCES_UPDATE_FAILED');
			throw error;
		}
	};

	return (
		<>
			{!destination && (
				<RequestCode
					sendCode={async () => setDestination(await sendCode())}
				>
					<CtaButton variant="secondary" onClick={() => back()}>
						{t('go_back')}
					</CtaButton>
				</RequestCode>
			)}
			{destination && !formConfig && (
				<CheckCode
					sendCode={sendCode}
					back={() => back()}
					checkCode={async (code: string) =>
						setFormConfig(await checkCodeAndGetFormConfig(code))
					}
					destination={destination}
				/>
			)}
			{formConfig && (
				<EditPreferences
					config={formConfig}
					onSubmit={updatePreferences}
				>
					<CtaButton
						variant="secondary"
						data-name="go-back"
						onClick={() => back()}
					>
						{t('go_back')}
					</CtaButton>
				</EditPreferences>
			)}
		</>
	);
};
