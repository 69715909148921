import { ButtonHTMLAttributes } from 'react';
import { cls } from '../../utils/cls';
import styles from './CtaButton.module.scss';

interface CtaButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'primary' | 'secondary';
	pulse?: boolean;
}

export const CtaButton = ({
	variant = 'primary',
	children,
	className,
	pulse = false,
	...props
}: CtaButtonProps) => (
	<button
		className={cls(
			styles.Cta,
			variant === 'secondary' && styles.Secondary,
			pulse && styles.PulseSlowly,
			className
		)}
		{...props}
	>
		{children}
	</button>
);
