import { forwardRef, useEffect, useState } from 'react';
import ClapButton from 'react-clap-button';
import { useGlobalSettings } from 'hooks/useGlobalSettings';
import { usePlayerUpdates } from 'kaltura-player';
import { Icon } from 'components';
import styles from './ControlBar.module.scss';
import { gv_analytics } from 'utils/Analytics';
import { logClap } from 'utils/backend';

export const ControlClap = ({ playerId, size }) => {
	const GLOBAL_SETTINGS = useGlobalSettings();
	const { getPlayerTime } = usePlayerUpdates(playerId);

	const handleClap = (e) => {
		logClap(GLOBAL_SETTINGS, parseInt(getPlayerTime() / 1000));
		gv_analytics.capture('USER_CLAPPED', {
			timestamp: parseInt(getPlayerTime() / 1000),
		});
	};
	const calculateCircleSize = () => Math.min(window.innerHeight / 12, 70);
	const [circleSize, setCircleSize] = useState(calculateCircleSize());
	const iconRatio = 0.55;
	useEffect(() => {
		window.onresize = () => setCircleSize(() => calculateCircleSize());
		return () => {
			window.onresize = null;
		};
	}, []);

	return (
		// Must use a parent div instead of the ClapButton's built-in onCountChange prop
		// See: https://github.com/Kikobeats/react-clap-button/issues/12
		<div
			data-name="ControlClap"
			onClick={handleClap}
			className={styles.ClapButton}
		>
			<ClapButton
				className={styles.clap}
				countTotal={GLOBAL_SETTINGS.video.nbClaps}
				theme={{
					primaryColor: styles.primaryColor,
					secondaryColor: styles.secondaryColor,
					size: circleSize,
				}}
				iconComponent={forwardRef((_, ref) => (
					<Icon
						ref={ref}
						name="clap"
						color={styles.primaryColor}
						size={circleSize * iconRatio}
					/>
				))}
			/>
		</div>
	);
};
