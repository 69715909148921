import { useEffect, useState } from "react";

type Callback = (...args: unknown[]) => void;

const debounced = (delay: number, fn: Callback): Callback => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      clearTimeout(timeout);
      fn(...args);
    }, delay);
  };
};

const isScrolled = (y: number) => y > document.documentElement.clientHeight;

export const useIsScrolled = (shouldDisplay = isScrolled): boolean => {
  const [display, setDisplayed] = useState(false);

  useEffect(() => {
    const onScroll = debounced(100, () =>
      setDisplayed(shouldDisplay(window.pageYOffset))
    );
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [shouldDisplay]);

  return display;
};
