import { KalturaPlayer } from 'kaltura-player';
import { useState, useEffect } from 'react';
import removeLoadingOverlay from 'utils/clearAnimation';
import { playerContainer } from './VideoPlayer.module.scss';

export const PlayerContainer = ({
	entryId,
	setPlayerId,
	children,
	kavaProperties,
}) => {
	const [playerIsLoaded, setplayerIsLoaded] = useState(false);
	const handlePlayerLoaded = ({ playerId }) => {
		playerId && setPlayerId(playerId);
		playerId && setplayerIsLoaded(true);
	};

	useEffect(() => {
		if (playerIsLoaded) removeLoadingOverlay();
	}, [playerIsLoaded]);

	return (
		<>
			<div className={['ph-no-capture', playerContainer].join(' ')}>
				<KalturaPlayer
					entryId={entryId}
					autoplay={false}
					onPlayerLoaded={handlePlayerLoaded}
					enableKavaAnalytics={!!kavaProperties}
					kavaProperties={kavaProperties}
					customizeConfig={{
						playback: {
							preload: process.env.NODE_ENV === 'production',
							pictureInPicture: false,
						},
					}}
				/>
			</div>
			{children}
		</>
	);
};
