import React from 'react';
import { Controller } from 'react-hook-form';
import { cls } from 'utils/cls';
import styles from './ToggleButton.module.scss';

export const ToggleButton = ({
	classNames,
	name,
	onChange,
	options,
	value: selected,
	...props
}) => (
	<div
		tabIndex="0"
		className={cls(styles.ToggleButton, classNames)}
		data-name={name}
		{...props}
	>
		{options.map(({ label, value }) => (
			<span
				key={value}
				data-name={value}
				onClick={() => onChange(value)}
				role="button"
				aria-pressed={selected === value}
				className={styles.Option}
			>
				{label}
			</span>
		))}
	</div>
);

export const FormToggleButton = ({ control, name, options }) => (
	<Controller
		render={({ field: { ref, ...props } }) => (
			<ToggleButton {...props} options={options} />
		)}
		name={name}
		control={control}
	/>
);
