import { useEffect } from 'react';
import { usePlayer } from 'kaltura-player';
import { Icon } from 'components/Icons/Icon';
import { gv_analytics } from 'utils/Analytics';

export const ControlFullScreen = ({ playerId, size }) => {
	const { getPlayerInstance } = usePlayer(playerId);
	const toggle = () => {
		const playerInstance = getPlayerInstance();
		if (!playerInstance) return;
		playerInstance.enterFullscreen();
	};

	const logFsChange = (e) =>
		gv_analytics.capture(
			(document.fullscreenElement ? 'ENTERED' : 'EXITED') + '_FULLSCREEN'
		);

	useEffect(() => {
		document.addEventListener('fullscreenchange', logFsChange);
		return () => {
			document.removeEventListener('fullscreenchange', logFsChange);
		};
	}, []);

	return <Icon name={'fullscreen'} size={size || 35} onClick={toggle} />;
};
