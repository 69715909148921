import * as Sentry from '@sentry/react';
import { Loaded } from '../components/Common/Page.module.scss';
// Pursuant to https://github.com/danielthedifficult/gv-front/issues/54
// This script is used as a 'callback' for when the app is ready, and should gracefully remove the loading overlay.
// Future improvements could include gracefully transitioning opacity to zero, and using the animationEnd callback to finally remove it from the DOM.
// GEFN though, as we fade in the player and that's pretty ;)

export default function removeLoadingOverlay() {
	try {
		const loader = document.getElementById('loader');
		if (loader) loader.remove();
		document
			.querySelectorAll("[data-name='Page']")[0]
			?.classList.add(Loaded);
		clearInterval(window.updateInterval); // Clear loading bar
	} catch (e) {
		let errorMessage = 'Could not cleanly dismiss loader animation: ';
		console.error(errorMessage, e);
		Sentry.captureMessage(errorMessage);
	}
}
