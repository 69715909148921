import { createContext, useContext } from 'react';

interface Session {
	GV_CODE: string;
	video: {
		id: string;
		videoID: string;
		language: string;
		name: string;
		nbClaps: number;
	};
	userData: {
		companyID: number;
		userID: number;
		analyticsID: string;
		gateway: 'SMS' | 'SMS-OP' | 'Email' | 'Email-HTML';
		company: string;
		companyName: string;
		CDN_SESSION_ID: string;
		unsubToken?: string;
		videoRating: number;
		// cookies: string;
	};
	featuresConfig: {
		enableAPL: boolean;
		enableEpisodeHistory: boolean;
		enableFeedback: boolean;
		enableSharing: boolean;
		custom_feedback_email?: string;
	};
	disable_analytics: boolean;
	votingInfo:
		| {
				voteDuration: number;
				votePrompt: string;
				voteAnswers: string[];
				// allowFullScreenBeforeVoting: string;
				showQuestionTime: string;
				showAnswersDelay: number;
				showNeutralAnswerDelay: number;
				defaultAnswer: string;
				timerFX_URL: string;
				celebrationFX_URL: string;
		  }
		| false;
}

export interface GlobalSetting extends Session {
	IS_PROD?: boolean;
	from_shared_link?: boolean;
	contactEmail?: string;
}

const globalSettingsContext = createContext<GlobalSetting | null>(null);

export const GlobalSettingsProvider = globalSettingsContext.Provider;

export const useGlobalSettings = () => {
	const settings = useContext(globalSettingsContext);
	if (!settings)
		throw new Error(
			'useGlobalSettings must be used within a GlobalSettingsProvider, see https://reactjs.org/docs/context.html'
		);
	return settings;
};

export interface UIParams {
	show_header: boolean; // header + GV logo
	show_footer: boolean; // whole footer
	show_clap: boolean; // clap button
	// show_clap_count: boolean, // clap count
	show_unsub: boolean; // unsubscribe link in footer
	set_bg_color: boolean | string; // background
	show_landing: boolean; // landing card (includes rating and welcome back)
	show_apl: boolean; // aller plus loin
	show_fullscreen: boolean; // fullscreen button
	show_apl_episode_history: boolean; // Showing past episodes sent
}

const uiSettingsContext = createContext<UIParams | null>(null);

export const UiSettingsProvider = uiSettingsContext.Provider;

export const useUiSettings = () => {
	const settings = useContext(uiSettingsContext);
	if (!settings)
		throw new Error(
			'useUiSettings must be used within a uiSettingsProvider, see https://reactjs.org/docs/context.html'
		);
	return settings;
};
