import { gv_analytics } from './Analytics';

/**
 * getCodeFromUrl
 * Attempts to extact a valid shortlink code or session code from the path.
 */
function getCodeFromURL(_path = window.location.href) {
	return new Promise((resolve, reject) => {
		// First search for matching 'entrance' pattern (/c/, /session/, or ?play=) but don't return it
		// Then capture the GV session or shortlink code that comes after, until an end character (like a hash, !, or whatever else)
		try {
			var CODEX =
				/(?:\/c\/|\/meta\/|\/email\/|\/lead\/|\/session\/|\/preferences\/|\/share\/|\/embed\/|\/player\/|\/apl\/|play=|from_legacy_link=|from_shared_link=)([a-zA-Z0-9\-._=]+)(#|!|&|$|\?){0}/;
			let match = _path.match(CODEX);
			resolve(match[1]);
		} catch (e) {
			console.error('Could not get valid code from path:', _path);
			reject(new Error('MISSING_LINK_CODE', { cause: e }));
		}
	});
}

/**
 * getConfigFromPath
 * Attempts to extact a valid ui config string from the url.
 */
function getConfigFromURL(_path = window.location.href) {
	// Capture config param from URL
	try {
		const match = _path.match(
			/(?:config=)([a-zA-Z0-9\-._=]+)(#|!|&|$|\?){0}/
		);
		// console.log('Matched config: ', match);
		return match[1];
	} catch (e) {
		// console.log("No config param found in URL", _path)
		return null;
	}
}

/**
 * Extracts JS object from base64 encoded string
 */
function extractObjectFromB64(string) {
	if (!string) return {};
	try {
		const extractedConfig = JSON.parse(Buffer.from(string, 'base64'));
		return extractedConfig;
	} catch (e) {
		// console.warn(
		// 	'Error extracting config from parameter string. Check that the configuration parameter is correctly formulated.'
		// );
		// return {};
		throw new Error('COULD_NOT_PARSE_CONFIG_STRING', { cause: e });
	}
}

const getUiConfig = () =>
	Object.assign(
		{
			show_header: process.env.REACT_APP_UI_SHOW_HEADER === 'true', // header + GV logo
			show_footer: process.env.REACT_APP_UI_SHOW_FOOTER === 'true', // whole footer
			show_clap: process.env.REACT_APP_UI_SHOW_CLAP === 'true', // clap button
			// show_clap_count: process.env.REACT_APP_UI_SHOW_!== "false", // clap count
			defaultCard: getHash() || process.env.REACT_APP_UI_DEFAULT_CARD,
			show_unsub: process.env.REACT_APP_UI_SHOW_UNSUB === 'true', // unsubscribe link in footer
			show_preferences:
				process.env.REACT_APP_UI_SHOW_PREFERENCES === 'true', // Preferences link in footer
			set_bg_color: undefined, // background
			show_landing: process.env.REACT_APP_UI_SHOW_LANDING === 'true', // landing card (includes rating and welcome back)
			show_apl: process.env.REACT_APP_UI_SHOW_APL === 'true', // aller plus loin
			show_apl_bio: process.env.REACT_APP_UI_SHOW_APL_BIO !== 'false', // Biography Cards in APL, show by default
			show_apl_news: process.env.REACT_APP_UI_SHOW_APL_NEWS !== 'false', // News Cards in APL, show by default
			show_apl_quotes:
				process.env.REACT_APP_UI_SHOW_APL_QUOTES !== 'false', // QuoteCards in APL
			show_apl_media: process.env.REACT_APP_UI_SHOW_APL_MEDIA !== 'false', // LinkCards in APL
			show_apl_episode_history:
				process.env.REACT_APP_UI_SHOW_APL_EPISODE_HISTORY !== 'false', // Show previously sent episodes in APL
			show_fullscreen:
				process.env.REACT_APP_UI_SHOW_FULLSCREEN === 'true', // fullscreen button
		},
		extractObjectFromB64(getConfigFromURL()) || {}
	);

function getHash(string = window.location.hash) {
	return string?.slice(1).toLowerCase();
}

/**
 * @param {*} object takes a JS object and returns it stringified and encoded in base64
 */
function jsObjectToB64String(object) {
	let objectAsString = JSON.stringify(object);
	let buff = Buffer.from(objectAsString, 'utf-8');
	const base64 = buff.toString('base64');
	return base64;
}

/** getParams
 * Returns a an object of key/value pairs parsed from an RFC 3986 type queryString
 */
function queryStringToObject(queryString = '') {
	var params = {};
	if (queryString === '' || !queryString.includes('?')) return params; // return empty object for blank string
	var array_of_params = queryString
		.split('?')[1]
		.split('&')
		.map(function (i) {
			return i.split('=');
		});
	for (let p in array_of_params) {
		params[array_of_params[p][0]] = array_of_params[p][1];
	}
	return params;
}

function queryStringToJSONString(object) {
	return JSON.stringify(queryStringToObject(object));
}

/**
 *
 * @param {*} string should actually be a JSON array like ['//sub.domain.com/path']
 * NOTE: this will only return the first element!
 * @returns
 */
function parseUrlFromJson(string) {
	try {
		let parsed = JSON.parse(string)[0];
		let url = parsed.replace(
			'//cf.appdrag.com/goodvibes-474a9a/',
			'https://app.goodvibes.news/'
		);
		return url;
	} catch (e) {
		return null;
	}
}

/**
* Used to read and write to the local storage and to fallback on cookies if local storage isn't available on a device/browser.

The prototype is : store(key, value, onlyCookie);
 * @param n Key of value you want store
 * @param t The value you want to store
 * @param i Force the device to use Cookies instead of localStorage
 * @returns 
 */
function store(n, t, i) {
	function u(n, t, i) {
		var r = new Date(),
			u;
		r.setTime(r.getTime() + i * 864e5);
		u = '; expires=' + r.toGMTString();
		document.cookie = n + '=' + t + u + '; path=/';
	}
	function f(n) {
		for (
			var t,
				r = n + '=',
				u = document.cookie.split(';'),
				i = 0,
				f = u.length;
			i < f;
			i++
		) {
			for (t = u[i]; t.charAt(0) === ' '; ) t = t.substring(1, t.length);
			if (t.indexOf(r) === 0) return t.substring(r.length, t.length);
		}
		return null;
	}
	var r = !1;
	try {
		localStorage && (r = !0);
	} catch (e) {}
	if (
		(typeof t != 'undefined' &&
			t !== null &&
			(typeof t !== 'object' && (t = JSON.stringify(t)),
			r && i !== !0 ? localStorage.setItem(n, t) : u(n, t, 30)),
		typeof t == 'undefined')
	) {
		let data = r && i !== !0 ? localStorage.getItem(n) : f(n);
		try {
			data = JSON.parse(data);
		} catch (o) {
			// console.log(o, data)
		}
		return data;
	}
	t === null && (r && i !== !0 ? localStorage.removeItem(n) : u(n, '', -1));
}

// Find 1-3 contiguous non-whitespace characters (letters, including accented/unicode characters) that are preceded and followed by a space|newline
function manageLineBreaks(str) {
	str = dontBreakOnSmallWords(str);
	str = makeHyphensNonBreak(str);
	str = makeFrenchPunctuationNonBreak(str);
	return str;
}
const NBSP = '\u00A0';
// Rules:
// Add a non-break space BEFORE words with 3 letters or less
// UNLESS it follows a punctuation mark (i.e. A short word is starting a new sentence)
export const dontBreakOnSmallWords = (string) =>
	string.replace(/([^!?:;])(\s)(?:\w{1,3})(?: )/gm, (match) =>
		match.replace(' ', NBSP)
	);

export const makeHyphensNonBreak = (string) =>
	string.replace(/\u2010|-/gm, '\u2011');

export const makeFrenchPunctuationNonBreak = (string) =>
	string.replace(/((?:[«])(\s?)|(\s?)(?:[?!;:»]))/gm, (match) =>
		match.replace(' ', NBSP)
	);

// Possible new regex ?
// /(\w+)(\s?)([?!:»])(\s|$)/u from https://github.com/mundschenk-at/wp-typography/commit/68358e6a622b75130514df0b2a7ea740fab8809c

function storeVideoState(key, state) {
	store(`${key}_${state}`, new Date().toISOString());
}

const gvShareMethods = {
	get: {
		link(code) {
			return `https://${window.location.host}/share/${code}`;
		},
		email_href({ email = '', title, text, code = '' }) {
			let encodedSubjectLine = encodeURIComponent(title);
			let encodedBody = encodeURIComponent(text.long);
			return `mailto:${email}?subject=${encodedSubjectLine}&body=${encodedBody}${
				code ? '%0D%0A%0D%0A' + this.link(code) : ''
			}`;
		},
		sms_href({ text, platform, code }) {
			// if (/Win|BlackBerry|Linux/.test(platform)) return false; // Disabled this because Windows can support this, and Linux is needed for GH actions to test it.
			let protocol =
				platform.toUpperCase().indexOf('MAC') >= 0 ? 'imessage' : 'sms';
			let separator = /(Mac|iPhone|iPod|iPad)/i.test(platform)
				? '//&'
				: '?';
			let link = `${protocol}:${separator}body=${encodeURIComponent(
				text.short
			)}%0A%0A${this.link(code)}`;
			return link;
		},
		whatsapp_href({ text }) {
			// Note: according to https://faq.whatsapp.com/iphone/how-to-link-to-whatsapp-from-a-different-app/?lang=en
			// It might be better/more compatible to use a Universal Link format, such as:
			// https://wa.me/?text=I'm%20inquiring%20about%20the%20apartment%20listing
			// This provides a WhatsApp controlled page with CTAs for opening the native app and/or downloading WhatsApp. Better UX than nothing happening
			return `whatsapp://send&text=${encodeURIComponent(text)}`;
		},
	},
	via: {
		sms({ code }) {
			try {
				gv_analytics.capture('USER_SHARE', { code, channel: 'SMS' });
			} catch (e) {
				console.error(e);
			}
		},
		email({ code }) {
			try {
				gv_analytics.capture('USER_SHARE', { code, channel: 'EMAIL' });
			} catch (e) {
				console.error(e);
			}
		},
		clipboard: async ({ code }, confirmation_message, target = null) => {
			return new Promise(async (resolve, reject) => {
				if (target) target.attr.copied = gvShareMethods.get.link(code);
				await navigator.clipboard
					.writeText(gvShareMethods.get.link(code))
					.then(resolve)
					.catch((err) => {
						console.error(err);
						reject();
					});
				gv_analytics.capture('USER_SHARE', {
					code,
					channel: 'CLIPBOARD',
				});
			});
		},
		native: async ({ title, text, url, code }) => {
			if (
				navigator.canShare &&
				navigator.canShare({ title, text, url, code })
			) {
				// if navigator native share is supported, use it
				// console.log("Sharing via native API")
				return await navigator
					.share({ title, text, url })
					.then(() => {
						// Return true to show that native api worked.
						try {
							gv_analytics.capture('USER_SHARE', {
								code,
								channel: 'NATIVE_SHARE_API',
							});
						} catch (e) {
							console.error(e);
						}
						console.log('Shared natively');
						return true;
					})
					.catch((e) => {
						return false; // don't get fancy
						// eslint-disable-next-line no-unreachable
						console.error(e.message);
						if (e.message.indexOf('cancel') !== -1) {
							console.log('Native shared failed');
							return true; // give user chance to retry via native share if they abort themselves
						} else {
							console.log('Native share failed!');
							return false;
						}
					});
			} else {
				// else, return false to show that native share didn't work
				// console.log("No native API")
				return false;
			}
		},
	},
};

window.gvShareMethods = gvShareMethods;

const filterMSBots = (code) => {
	if (code.length < 12) return code; // Don't check bots against shortlinks, because we don't sent shortlinks via email, so unlikely to be Microsoft ATP
	// console.log('Evaluating:', code);

	let ivefbVE, dbzcbalVE, ybt_dyvdx, hffeVE;
	try {
		({ ivefbVE, dbzcbalVE, ybt_dyvdx, hffeVE } =
			extractObjectFromB64(code));
	} catch (e) {
		throw new Error('INVALID_LINK_CODE');
	}
	if (ivefbVE || dbzcbalVE || ybt_dyvdx || hffeVE)
		throw new Error('IS_MS_BOT');
	else return code;
};

// GET SESSION CODE, SEND TO 404 IF DOESN'T EXIST
const redirectIfLegacyUrl = (error) => {
	return new Promise((resolve, reject) => {
		// INCOMING URL HANDLING
		var URL_PARAMS = new URLSearchParams(window.location.search);
		// Handle legacy links with explicit parameters by converting to new parameter format and reloading the page
		if (URL_PARAMS.has('videoID')) {
			// console.log('Legacy URL detected...');
			let all_params = queryStringToObject(window.location.search);
			let video_params = {};
			let utm_params = {};
			for (const param in all_params) {
				if (typeof all_params[param] === 'undefined') break;
				if (param.includes('utm_')) {
					utm_params[param] = all_params[param];
				} else {
					video_params[param] = all_params[param];
				}
			}

			window.location.search =
				'?' +
				new URLSearchParams({
					from_legacy_link: btoa(JSON.stringify(video_params)),
					...utm_params,
				}).toString();
			return; // Don't resolve, we don't want to continue the parent .then chain, just let the browser navigate to the new page!
		} else reject(error); // Passthrough the original error if it isn't a legacy link
	});
};

const ignoreMissingLinkInDev = (e) => {
	console.warn(
		'sendTo404 called!',
		process.env.CONTEXT === 'production',
		document.cookie.indexOf('test404'),
		document.cookie
	);
	return new Promise((resolve, reject) => {
		if (
			process.env.CONTEXT === 'production' ||
			document.cookie.indexOf('test404') !== -1 // does test404 cookie exist, whatever the value
		) {
			throw e;
		} else {
			console.error(e);
			console.error(
				`ALERT: Would have failed in prod with error: ${e.message}. Will attempt to continue. Warning: Invalid GV session codes will cause unexpected errors in the script after this time.`
			);
			resolve(false);
		}
	});
};

const applyUserCookies = (cookiesToStore = [], d = document) => {
	// console.log('applying cookies', cookiesToStore);
	var expiration_date = new Date(
		new Date().getTime() + 1000 * 60 * 60 * 24 * 30 * 13
	); // 13 months
	for (let cookie in cookiesToStore) {
		d.cookie = `${
			cookiesToStore[cookie]
		}=${new Date().toISOString()}; Domain=${
			window.location.host
		}; Path=/; Expires=${expiration_date.toUTCString()}`;
	}
};

function scrollTo(el) {
	if (el) document.getElementById(el)?.scrollIntoView();
	else console.warn('Called scrollTo with a falsy value!');
}

/* titleCase  
  _   _ _   _       _____               
 | | (_) | | |     / ____|              
 | |_ _| |_| | ___| |     __ _ ___  ___ 
 | __| | __| |/ _ \ |    / _` / __|/ _ \
 | |_| | |_| |  __/ |___| (_| \__ \  __/
  \__|_|\__|_|\___|\_____\__,_|___/\___|
                                        
                                                              
* Simple function - takes a string of one or more words, separated by a space or a hyphen, returns the string with the first letter of each word capitalized.
* NOTE: This will not play nicely with Scottish last names... like McMulroy... but it's only meant to work on First Names
*/

// NOTE - THIS IS ALSO IN CORE-LIBS FOR USE WITH THE BACKEND. THE BELOW CODE WILL ONLY BE USED IN FRONT-END
const titleCase = (str = '') =>
	str
		.toLowerCase()
		// Split string on each space. Take the resulting 'word' and set first letter to uppercase, lowercase the rest of it.
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
		// Split string on each hyphen. Take the resulting 'word' and set first letter to uppercase, lowercase the rest of it.
		.split('-')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join('-');

function isFullscreen() {
	if (
		document.isFullScreen ||
		document.fullscreenElement ||
		document.webkitFullscreenElement ||
		document.mozFullScreenElement
	)
		return true;
	else return false;
}

async function exitFullScreen() {
	try {
		if (document.exitFullscreen) {
			return await document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			/* Firefox */
			return await document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			/* Chrome, Safari and Opera */
			return await document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			/* IE/Edge */
			return await document.msExitFullscreen();
		}
	} catch (e) {
		console.log('Could not exit fullscreen', e);
	}
}

export {
	parseUrlFromJson,
	getCodeFromURL,
	getConfigFromURL,
	extractObjectFromB64,
	getUiConfig,
	jsObjectToB64String,
	getHash,
	gvShareMethods,
	titleCase,
	queryStringToObject,
	queryStringToJSONString,
	store,
	manageLineBreaks,
	storeVideoState,
	redirectIfLegacyUrl,
	ignoreMissingLinkInDev,
	applyUserCookies,
	scrollTo,
	filterMSBots,
	isFullscreen,
	exitFullScreen,
};
