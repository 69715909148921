import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import type { Fields } from 'api/getCompanySignupInfo/signup.types';
import type { Timezone } from 'api/UserPreferences/preferences.types';
import type { UserData } from 'api/UserPreferencesUpdate/user.types';
import { Card } from 'components/Common/Card';
import { StepForm } from 'components/Signup/StepForm';

import styles from './Preferences.module.scss';

export type FormConfig = {
	userData: UserData;
	fields: Fields;
	timezones: Timezone[];
};

type EditPreferencesProps = {
	config: FormConfig;
	onSubmit: (data: UserData) => void;
	children?: ReactNode;
};

export const EditPreferences = ({
	config,
	onSubmit,
	children,
}: EditPreferencesProps) => {
	const { t } = useTranslation();

	return (
		<Card className={styles.PreferencesCard}>
			<StepForm
				defaultValues={config.userData}
				fields={config.fields}
				onSubmit={onSubmit}
				title={t('preferences_form_title')}
				subtitle={t('preferences_form_subtitle')}
				submitLabel={t('preferences_form_submit_cta')}
			>
				{children}
			</StepForm>
		</Card>
	);
};
