import React, { useState, useEffect } from 'react';
import { CtaButton, Card, Icon } from 'components';
import styles from './Tutorial.module.scss';
import { useTranslation } from 'react-i18next';
import { useUiSettings } from 'hooks/useGlobalSettings';

export const Tutorial = ({ toggleTutorial, active }) => {
	const { t } = useTranslation();
	const [opacity, setOpacity] = useState(0);
	const { show_clap, show_landing } = useUiSettings();
	useEffect(() => {
		setOpacity(1);
		return () => setOpacity(0);
	}, []);

	const lines = [
		{
			iconName: 'clap',
			lineTitle: 'tutorial_clap',
			lineExplanation: 'tutorial_clap_explanation',
			enabled: show_clap,
		},
		{
			iconName: 'rewind',
			lineTitle: 'tutorial_rewind',
			lineExplanation: 'tutorial_rewind_explanation',
		},
		{
			iconName: 'sondages',
			lineTitle: 'tutorial_poll',
			lineExplanation: 'tutorial_poll_explanation',
		},
		{
			iconName: 'comments',
			lineTitle: 'tutorial_thoughts',
			lineExplanation: 'tutorial_thoughts_explanation',
			enabled: show_landing,
		},
		{
			iconName: 'send',
			lineTitle: 'tutorial_share',
			lineExplanation: 'tutorial_share_explanation',
			enabled: show_landing,
		},
		// {
		// 	iconName: "transcription",
		// 	lineTitle: "tutorial_transcription",
		// 	lineExplanation: "tutorial_transcription_explanation",
		// },
	];

	return (
		<Card
			id={'tutorial'}
			className={styles.TutorialCard}
			style={{ opacity: opacity }}
			data-name="Tutorial"
		>
			<div className={styles.CardContent}>
				<h2>{t('how_does_it_work')}</h2>
				<div className={styles.LinesContainer}>
					{lines.map((line) => (
						<TutorialLine
							key={line.lineTitle.toString()}
							iconName={line.iconName}
							lineTitle={line.lineTitle}
							lineExplanation={line.lineExplanation}
						/>
					))}
				</div>
				<CtaButton
					style={{ pointerEvents: active ? 'auto' : 'none' }} // This prevents the user's mouse from triggering button animations after clicking but during/immediately after a switch
					onClick={toggleTutorial}
				>
					{t('understood')}
				</CtaButton>
			</div>
		</Card>
	);
};

export const TutorialLine = ({ iconName, lineTitle, lineExplanation }) => {
	const { t } = useTranslation();
	return (
		<span className={styles.Section}>
			<Icon name={iconName} size={48} />
			<div className={styles.LineContent}>
				<h3 className={styles.LineTitle}>{t(lineTitle)}</h3>
				<div className={styles.LineExplanation}>
					{t(lineExplanation)}
				</div>
			</div>
		</span>
	);
};
