import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';

import { Card } from 'components';

import styles from './VoteResultsCard.module.scss';

export const VoteResultsCard = ({ id, question, counts, answers, date }) => {
	const { t } = useTranslation();

	return (
		<Card
			id={id}
			className={styles.VoteResultsCard}
			data-name="VoteResultsCard"
		>
			<div className={styles.VoteResultsContainer}>
				<h2>{t('others_opinion')}</h2>
				<h4>{question}</h4>
				<div className={styles.Results}>
					{answers?.map(({ label, results }, index) => {
						if (!results) return null;
						const { company, others } = results;
						return (
							<div key={index} className={styles.Result}>
								<Markdown className={styles.Label}>
									{'####' + (label || t('no_answer'))}
								</Markdown>
								{others.percent ? (
									<div>
										<div
											className={`${styles.ProgressBar} ${styles.others}`}
											style={{
												width: `${
													others.percent * 100
												}%`,
											}}
										/>
										<span className={styles.Value}>
											{(others.percent * 100).toFixed(2)}%
										</span>
									</div>
								) : null}
								{company.percent ? (
									<div>
										<div
											className={`${styles.ProgressBar} ${styles.company}`}
											style={{
												width: `${
													company.percent * 100
												}%`,
											}}
										/>
										<span className={styles.Value}>
											{(company.percent * 100).toFixed(2)}
											%
										</span>
									</div>
								) : null}
							</div>
						);
					})}
				</div>
				<ul className={styles.Legend}>
					<li>
						<div
							className={`${styles.ProgressBar} ${styles.others}`}
						/>
						<span className={styles.Value}>
							{t('votes_others')}
						</span>
					</li>
					<li>
						<div
							className={`${styles.ProgressBar} ${styles.company}`}
						/>
						<span className={styles.Value}>
							{t('votes_company')}
						</span>
					</li>
				</ul>
				<p>
					{/* 
					Removed per Floriane Avril - we want to wait to have bigger numbers before publishing this information.
					<Trans
						i18nKey="votes_counts"
						values={{
							othersCount:
								(counts?.others?.votes || 0) +
								(counts?.company?.votes || 0),
							companyCount: counts?.company?.votes || 0,
						}}
						components={{
							others: <strong className={styles.others} />,
							company: <strong className={styles.company} />,
						}}
					/> */}
					{t('votes_date', {
						date: new Date(date).toLocaleDateString(),
					})}
				</p>
			</div>
		</Card>
	);
};
