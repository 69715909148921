import styles from './Voting.module.scss';
import { manageLineBreaks } from 'utils/utils';
import Markdown from 'markdown-to-jsx';

function getQuestionFontSize(questionLength, numAnswers) {
	var baseFontSize = 4.25;
	if (questionLength > 40) baseFontSize = baseFontSize - questionLength * 0.01;
	if (numAnswers > 3) baseFontSize = baseFontSize - 0.5;
	return baseFontSize + 'vh';
}

export const VoteQuestion = ({ text, numAnswers }) => {
	let fontSizeBasedOnNumAnswersAndQuestionLength = getQuestionFontSize(
		text.length,
		numAnswers
	);

	return (
		<div data-name="VoteQuestion" className={styles.QuestionContainer}>
				<Markdown 
					style={{ fontSize: fontSizeBasedOnNumAnswersAndQuestionLength }}
					className={styles.QuestionText}
				>
					{manageLineBreaks(text)}
				</Markdown>
		</div>
	);
};
