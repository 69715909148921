import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { manageLineBreaks } from '../utils/utils';
import playerTranslations from './playerTranslations.json';
import tutorialTranslations from './tutorialTranslations.json';
import signupLoginPreferences from './signupLoginPreferencesTranslations.json';
import themeTranslations from '../static/themeTranslations.json';

export const updateLanguage = (language = 'FR') => {
	i18n.changeLanguage(language);
	// Override generic/player translations with theme-specific translations.
	// NOTE: The below order of files should be in ASCENDING order of PRIORITY for the translation. So, the last object in the array should have the most important translations, as it will have the 'final say'.
	[
		playerTranslations,
		tutorialTranslations,
		signupLoginPreferences,
		themeTranslations,
	].forEach((translationFile) => addTranslation(language, translationFile));
};

function addTranslation(language, obj) {
	const validTranslation = obj[language]?.translation || obj.FR?.translation;
	// https://www.i18next.com/overview/api#addresourcebundle
	// i18next.addResourceBundle(lng, ns, resources, deep, overwrite)
	if (validTranslation)
		i18n.addResourceBundle(
			language,
			'translation',
			validTranslation,
			true,
			true
		);
}

export default i18n
	.use(initReactI18next)
	.use({
		type: 'postProcessor',
		name: 'manageLineBreaks',
		process: function (value, key, options, translator) {
			/* return manipulated value */
			return manageLineBreaks(value);
		},
	})
	.init({
		debug: false,
		// ns: ['translation', 'preferencesSignup', 'tutorial'],
		defaultNS: 'translation',
		saveMissing: true,
		postProcess: 'manageLineBreaks',
		missingKeyHandler: (lngs, ns, missing_key) => {
			if (process.env.NODE_ENV !== 'test') console.log('Missing translation key: ', lngs, ns, missing_key);
			Sentry.captureMessage(
				`Missing translation key for ${lngs} in ${ns} : ${missing_key}`
			);
		},
		fallbackLng: 'FR',
		lng: 'FR',
		react: { useSuspense: false },
		interpolation: {
			escapeValue: false,
			format: (value) => manageLineBreaks(value),
		},
	});
