import { useCallback, useEffect, useState } from 'react';
import { ControlBar, PlayerContainer, VoteInterface } from 'components';
import { useGlobalSettings } from 'hooks/useGlobalSettings';
import { usePlayer, usePlayerUpdates } from 'kaltura-player';
import { filter } from 'rxjs/operators';

export const VideoPlayer = ({ id: entryId, playerId, setPlayerId, muted }) => {
	const { playerTime$ } = usePlayerUpdates(playerId);
	const [showVoteUi, setShowVoteUi] = useState(false);
	const { disable_analytics, userData, video, votingInfo, from_shared_link } =
		useGlobalSettings();
	const votingEnabled = !!votingInfo;

	const { playerPlay, playerPause } = usePlayer(playerId);
	const [userWasFullscreen, setUserWasFullscreen] = useState();

	const { getPlayerInstance } = usePlayer(playerId);

	useEffect(() => {
		if (getPlayerInstance())
			setUserWasFullscreen(getPlayerInstance().isFullscreen());
		window.kalturaPlayer = getPlayerInstance();
	}, [getPlayerInstance]);

	// Hiding the voting interface
	const hideVoteUiFunction = useCallback(() => {
		if (!showVoteUi) return;
		// console.log('Hiding Vote UI!', userWasFullscreen);
		document.body.classList.remove('voting');
		setShowVoteUi(false);
		// if (userWasFullscreen) getPlayerInstance().enterFullscreen(); // Removed June 2022 because it was not honoring "userWasFullscreen", i.e. was putting users back in fullscreen no matter what.
		hidePlayerControls(false);
	}, [showVoteUi]);

	// Showing the voting interface
	const showVoteUiFunction = useCallback(
		(bool = votingEnabled) => {
			if (showVoteUi) return;
			// console.log('Showing Vote UI!');
			document.body.classList.add('voting'); // Important for CSS rules
			setUserWasFullscreen(
				getPlayerInstance()?.isFullscreen() ||
					document.webkitCurrentFullScreenElement !== null
			);

			getPlayerInstance().exitFullscreen();
			setShowVoteUi(bool);
			hidePlayerControls(true); // Need to hide the default play button that appears when the kaltura player is paused
			playerPause();
		},
		[getPlayerInstance, playerPause, showVoteUi, votingEnabled]
	);

	/* Monitor player time for right time to vote*/
	useEffect(() => {
		if (!votingEnabled || !getPlayerInstance()) return;
		// Don't show anything if voting is not enabled

		// Subscribe to playerTime updates, set variable to true when it's time
		let showTime = votingInfo.showQuestionTime * 1000;
		let hideTime = showTime + 700;

		const timeToShowVoteUiSubscription = playerTime$
			.pipe(filter((time) => time > showTime && time < hideTime))
			.subscribe(showVoteUiFunction);

		const timeToHideVoteUiSubscription = playerTime$
			.pipe(filter((time) => time > hideTime))
			.subscribe(hideVoteUiFunction);
		return () => {
			timeToShowVoteUiSubscription.unsubscribe();
			timeToHideVoteUiSubscription.unsubscribe();
		};
	}, [
		hideVoteUiFunction,
		playerId,
		getPlayerInstance,
		playerPause,
		playerTime$,
		showVoteUiFunction,
		userWasFullscreen,
		votingEnabled,
		votingInfo,
		votingInfo.showQuestionTime,
	]);

	// TODO revisit this : can we track shared analytics data ? perhaps by adding -shared to company and userIDs?
	const kavaProperties =
		disable_analytics || from_shared_link
			? false
			: {
					playbackContext: userData.analyticsID,
					customVar1: userData.companyID + ':' + userData.companyName,
					customVar2: video.videoID.slice(0, 8),
					customVar3: video.language,
					userId: userData.userID.toString(),
			  };

	return (
		<PlayerContainer
			entryId={entryId}
			setPlayerId={setPlayerId}
			kavaProperties={kavaProperties}
		>
			{/* important architectural detail! -> 
      if Voting is enabled, VideoPlayer will render VotingInterface at all times. 
      This is so that the logic contained in VotingInterface (like keeping track of the user's previous vote) and other elements such as the sound effects, remain in-memory. 
      
      At the appropriate time in the video, VotingInterface will return the DOM elements, 
      thus 'rendering' it to the screen. */}
			{votingEnabled ? (
				<VoteInterface
					show={showVoteUi}
					resumeVideo={playerPlay}
					muted={muted}
					{...votingInfo}
				/>
			) : null}
			{playerId && (
				<ControlBar playerId={playerId} isVoting={showVoteUi} />
			)}
		</PlayerContainer>
	);
};

const hidePlayerControls = (bool = true) => {
	let playerChrome = document.getElementsByClassName(
		'playkit-playback-controls playkit-center-playback-controls'
	);
	if (playerChrome.length > 0)
		playerChrome[0].style.display = bool === true ? 'none' : '';
};
