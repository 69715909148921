import { useState, useEffect, cloneElement, Children } from 'react';
import { ReactSVG } from 'react-svg';
import { useIsScrolled } from 'hooks/useIsScrolled';

import logo from 'static/img/logo.svg';

// styles coming from Page and Nav
import { Navigation, Hide, Show, Current } from './Nav.module.scss';
import { Logo } from './Page.module.scss';

export const Nav = ({ children }) => {
	const [current, setCurrent] = useState();
	const show = useIsScrolled((y) => y > 680);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				const element = entries.find((entry) => entry.isIntersecting);
				element && setCurrent(`#${element.target.id}`);
			},
			{ threshold: 0.75 }
		);
		Children.forEach(children, (link) => {
			const element = link && document.querySelector(link.props.href);
			element && observer.observe(element);
		});
		return () => observer.disconnect();
	}, [children]);

	return (
		<nav className={`${Navigation} ${show ? Show : Hide}`}>
			<ReactSVG src={logo} className={Logo} />
			{Children.map(
				children,
				(link) =>
					link &&
					cloneElement(link, {
						className:
							link.props.href === current
								? [Current, 'active'].join(' ')
								: null,
						onClick: () => setCurrent(link.props.href),
					})
			)}
		</nav>
	);
};
