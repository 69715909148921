import * as Sentry from '@sentry/react';
import { checkFetchError } from './checkFetchError';

export const logClap = (GLOBAL_SETTINGS, timestamp = 0) => {
	const {
		from_shared_link = false,
		disable_analytics,
		video: { videoID },
		userData: { userID, companyID },
	} = GLOBAL_SETTINGS; // Passed through

	const params = {
		userID,
		videoID,
		companyID,
		timestamp,
		from_shared_link, // Must be an integer 0 or 1 to be compatible with the SQL data type
	};

	if (disable_analytics)
		return console.log(
			'Would have recorded CLAP on server, however analytics are disabled.',
			'params: ',
			params
		);

	fetch(`${process.env.REACT_APP_API_URL}/Claps/AddClapsToVideo`, {
		// Endpoint requires Form-encoded vars
		body: new URLSearchParams(params),
		method: 'POST',
		async: true,
		crossDomain: true,
		processData: true,
	})
		.then(checkFetchError)
		.then((response) => {
			if (response.status === 'OK') {
				console.log(
					'Your Clap was successfully recorded on our servers.'
				);
			} else {
				Sentry.addBreadcrumb({
					category: 'error',
					data: response,
					level: 'Error',
				});
				Sentry.captureMessage('Error recording clap on backend!');
			}
		});
};

export const logRating = (GLOBAL_SETTINGS, rating) => {
	const {
		from_shared_link,
		disable_analytics,
		video: { videoID },
		userData: { userID, companyID },
	} = GLOBAL_SETTINGS; // Passed through

	const params = {
		userID,
		companyID,
		videoID,
		rating,
		from_shared_link, // Must be an integer 0 or 1 to be compatible with the SQL data type
	};

	if (disable_analytics) {
		console.log(
			'Would have recorded RATING on server, however analytics are disabled.',
			'params: ',
			params
		);
		return;
	}

	fetch(`${process.env.REACT_APP_API_URL}/Feedback/Rating`, {
		method: 'PUT',
		body: new URLSearchParams(params),
	})
		.then(checkFetchError)
		.then((response) => {
			Sentry.addBreadcrumb({
				category: 'xhr',
				data: response,
				level: 'Info',
			});
			if (response.status === 'OK') {
				console.log(
					'Your rating was successfully recorded on our servers.'
				);
			} else {
				Sentry.captureMessage('Error recording rating on backend!');
			}
		});
};

export const logVote = (
	GLOBAL_SETTINGS,
	vote,
	text = 'timeout',
	time_to_vote
) => {
	const {
		from_shared_link,
		disable_analytics,
		video: { videoID },
		userData: { userID, companyID },
	} = GLOBAL_SETTINGS; // Passed through

	const params = {
		userID,
		videoID,
		companyID,
		vote,
		time_to_vote,
		text,
		from_shared_link, // Must be an integer 0 or 1 to be compatible with the SQL data type
	};

	if (disable_analytics)
		return console.log(
			'Would have recorded VOTE on server, however analytics are disabled.',
			'params: ',
			params
		);

	var settings = {
		url: process.env.REACT_APP_API_URL + '/Votes/LogVotesFast',
		body: new URLSearchParams(params),
		method: 'PUT',
		async: true,
		crossDomain: true,
		processData: true,
	};
	return fetch(settings.url, settings)
		.then(checkFetchError)
		.then((response) => {
			if (response.status === 'OK')
				console.log(
					'Your vote was successfully recorded on our servers.'
				);
			else {
				Sentry.addBreadcrumb({
					category: 'warning',
					message: 'Breadcrumb message here',
					data: response,
					level: 'Warning',
				});
				console.warn(response);
				Sentry.captureMessage('Could not record vote on servers!');
			}
		});
};
