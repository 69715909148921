import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Icon, RatingIcon } from 'components';
import { gvShareMethods as share } from 'utils/utils';
import { gv_analytics } from 'utils/Analytics';
import { logRating } from 'utils/backend';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';

import {
	Landing,
	Content,
	Rating,
	Actions,
	ActionLink,
	ShareActions,
	ActionIcon,
	RatingContainer,
	RatingIcons,
	WelcomeBack,
	Icons,
	Good,
	Bad,
	Selected,
} from './LandingCard.module.scss';

export const LandingCard = ({
	id,
	next,
	isSmallDevice,
	showWelcomeBack = false,
	GLOBAL_SETTINGS,
	UI_SETTINGS,
}) => {
	const { t } = useTranslation();
	const {
		GV_CODE,
		contactEmail,
		video: { name },
		userData: { videoRating },
		featuresConfig: { enableFeedback, enableSharing, enableEpisodeHistory },
		past_episodes = [],
	} = GLOBAL_SETTINGS;
	let { show_apl_episode_history } = UI_SETTINGS;
	show_apl_episode_history =
		enableEpisodeHistory &&
		show_apl_episode_history &&
		past_episodes.length > 0;

	// Logic for setting up rating
	const [rating, setRating] = useState(videoRating);
	const updateRating = (rating) => {
		setRating(rating);
		logRating(GLOBAL_SETTINGS, rating);
		gv_analytics.capture('USER_RATED', { rating });
	};

	useEffect(() => {
		if (showWelcomeBack) gv_analytics.capture('USER_WELCOMED_BACK');
	}, [showWelcomeBack]);

	// Send feedback to editorial team
	const sendFeedbackEmailURL = share.get.email_href({
		email: contactEmail,
		title: t('feedback_message_subject', { name }),
		text: {
			long: t('feedback_message_body', { name }),
		},
	});

	// Share to a third party
	const [canShareNatively, setCanShareNatively] = useState(true); // Assume it works, if it doesn't we fallback to

	const [shareParams, setShareParams] = useState(null);
	useEffect(() => {
		setShareParams({
			title: t('share_message_subject', { name }),
			text: {
				short: t('share_message_body_short', { name }),
				long: t('share_message_body_long', { name }),
				html: t('share_message_body_html', { name }),
			},
			code: GV_CODE,
			platform: navigator.platform,
		});
	}, [GV_CODE, name, t]);

	const openShareOptions = async () => {
		// ALERT: Careful testing this in development, native sharing only works via https
		let sharedViaNativeAPI = await share.via.native({
			title: shareParams.title,
			text: shareParams.text.short + '\n\n',
			url: share.get.link(shareParams.code),
		});
		// If native share disabled, show our share buttons
		setCanShareNatively(sharedViaNativeAPI);
	};

	// truthy if user is on a small screen that we can assume is a mobile phone

	// if valid href generated based on share params and platform, show SMS
	const smsShareEnabled =
		(shareParams && share.get.sms_href(shareParams)) || // chain with shareParams to avoid running the function before shareParams are initialised
		isSmallDevice;

	return (
		<Card id={id} className={Landing} data-name="Landing">
			<div className={Content}>
				{showWelcomeBack ? (
					// If user is returning, welcome them back
					<div className={WelcomeBack} data-name={'WelcomeBack'}>
						<span>{t('welcome_back_msg_1/2')}</span>
						<span>{t('welcome_back_msg_2/2')}</span>
					</div>
				) : (
					// Otherwise, give them the opportunity to rate the video
					<div className={Rating} data-name={'LandingRating'}>
						<div className={RatingContainer}>
							<h2>{t('did_you_like_it')}</h2>
							<div className={RatingIcons}>
								<RatingIcon
									name={rating === 1 ? 'bad-active' : 'bad'}
									className={[
										Icons,
										Bad,
										rating === 1 ? Selected : '',
									].join(' ')}
									// active={rating === 1}
									onClick={() => updateRating(1)}
								/>
								<RatingIcon
									name={rating === 5 ? 'good-active' : 'good'}
									className={[
										Icons,
										Good,
										rating === 5 ? Selected : '',
									].join(' ')}
									// active={rating === 5}
									onClick={() => updateRating(5)}
								/>
							</div>
						</div>
					</div>
				)}
				{enableSharing ||
				show_apl_episode_history ||
				enableFeedback ||
				next ? (
					<div className={Actions} data-name={'APL-Actions'}>
						{enableSharing &&
							(canShareNatively ? (
								<span
									onClick={openShareOptions}
									className={ActionLink}
									data-name="share-via-native"
								>
									{/* <Icon name="play" size={48} /> */}
									{/* <picture className={ActionIcon}> */}
									<Icon
										className={ActionIcon}
										name="share"
										alt={name}
										size={48}
									/>
									{/* </picture> */}
									{t('share_these_GOOD_Vibes')}
									<Icon
										name="link"
										style={{ marginLeft: 'auto' }}
									/>
								</span>
							) : (
								<div id={'Sms'} className={ShareActions}>
									{smsShareEnabled && (
										<a
											href={
												shareParams &&
												share.get.sms_href(shareParams)
											} // chain with shareParams to avoid running the function before shareParams are initialised
											onClick={() =>
												share.via.sms(shareParams)
											}
											className={ActionLink}
											data-name="share-via-sms"
										>
											<Icon
												size={22}
												style={{ marginBottom: '5px' }}
												name="comments"
											/>
											<div>{t('sms_')}</div>
										</a>
									)}
									<a
										href={
											shareParams &&
											share.get.email_href(shareParams)
										} // chain with shareParams to avoid running the function before shareParams are initialised
										onClick={() =>
											share.via.email(shareParams)
										}
										className={ActionLink}
										data-name="share-via-email"
									>
										<Icon
											size={22}
											style={{ marginBottom: '5px' }}
											name="send"
										/>
										<div>{t('email_')}</div>
									</a>
									<span
										className={ActionLink}
										onClick={() =>
											share.via
												.clipboard(shareParams)
												.then(() => {
													toast.success(
														t('link_copied')
													);
												})
												.catch((err) => {
													Sentry.captureException(
														err
													);
													toast.error(
														t('toast_generic_error')
													);
												})
										}
										data-name="share-via-clipboard"
									>
										<Icon
											size={22}
											style={{ marginBottom: '5px' }}
											name="link"
										/>
										<div>{t('link_')}</div>
									</span>
								</div>
							))}
						{/* <a href="#video" onClick={replayVideo} className={ActionLink}> */}
						{/* <Icon name="play" size={48} /> */}
						{/* <picture className={ActionIcon}>
              <img
                src={`https://cfvod.kaltura.com/p/2508431/sp/f250843100/thumbnail/entry_id/${entryId}/width/100`}
                alt={name}
                height={48}
              />
            </picture>
            {t("view_again")}
            <Icon name="caretUp" />
          </a> */}
						{enableFeedback && (
							<a
								href={sendFeedbackEmailURL}
								data-name="send-feedback-email"
								onClick={() =>
									gv_analytics.capture('USER_SEND_FEEDBACK')
								}
								className={ActionLink}
							>
								<Icon
									className={ActionIcon}
									name="bulle"
									size={48}
								/>
								{t('share_opinion')}
								<Icon
									name="send"
									style={{ marginLeft: 'auto' }}
								/>
							</a>
						)}
						{show_apl_episode_history && (
							<a
								href={
									next
										? `#apl_past_episodes`
										: `#past_episodes`
								} //  if apl is enabled, scroll down to the past episodes at the end of APL. This is to show the user how much other content is available. On the other hand, if APl is not enabled, flip the player and just show the past episodes
								className={ActionLink}
								data-name="cta-past-episodes"
							>
								<Icon
									className={ActionIcon}
									name="apl_past_episodes"
									size={48}
								/>
								{t('watch_past_episodes')}
								<Icon
									name="rewind"
									style={{ marginLeft: 'auto' }}
								/>
							</a>
						)}
						{next && (
							<a href={`#${next}`} className={ActionLink}>
								<Icon
									className={ActionIcon}
									name="news"
									size={48}
								/>
								{t('go_further')}
								<Icon
									name="caretDown"
									style={{ marginLeft: 'auto' }}
								/>
							</a>
						)}
					</div>
				) : /* if nothing is enabled, don't show anything */
				null}
			</div>
		</Card>
	);
};
