import React, {
	forwardRef,
	HTMLAttributes,
	SVGAttributes,
	useEffect,
} from 'react';
import { render } from 'react-dom';
import { ReactSVG } from 'react-svg';

import icons from './icons.svg';
import socialIcons from './social-icons.svg';
import ratingIcons from './rating-icons.svg';

import styles from './Icon.module.scss';
import { cls } from 'utils/cls';

const sprites = new Map();
const injectSprite = (src: string) => {
	if (!sprites.has(src)) {
		sprites.set(src, document.body.appendChild(document.createElement('span')));
		render(<ReactSVG hidden src={src} />, sprites.get(src));
	}
};

interface IconProps extends SVGAttributes<SVGSVGElement> {
	name: string;
	color?: string;
	size?: number;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(
	({ name, color = 'currentColor', size = 16, ...props }, ref) => {
		useEffect(() => injectSprite(icons), []);

		return (
			<svg
				ref={ref}
				data-name={name}
				className={styles.Icon}
				width={size}
				height={size}
				fill={color}
				{...props}
			>
				<use href={`#icon-${name}`} />
			</svg>
		);
	}
);

interface SocialIconProps extends HTMLAttributes<HTMLImageElement> {
	name: string;
	size?: number;
}

export const SocialIcon = ({ name, size = 32, ...props }: SocialIconProps) => (
	<img
		alt={name}
		className={styles.Icon}
		width={size}
		height={size}
		{...props}
		src={`${socialIcons}#icon-${name}`}
	/>
);

interface RatingIconProps extends HTMLAttributes<HTMLImageElement> {
	name: string;
}

export const RatingIcon = ({
	name,
	className,
	style,
	...props
}: RatingIconProps) => (
	<img
		{...props}
		alt={name}
		className={cls(styles.Icon, className)}
		width={72}
		height={80}
		style={style}
		src={`${ratingIcons}#icon-${name}`}
	/>
);
