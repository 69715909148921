import { ReactNode, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CtaButton } from '../Common/CtaButton';
import {
	Form,
	FormField,
	FormPhoneField,
	FormSelect,
	OptionProps,
} from '../Common/Form';
import { FormToggleButton } from '../Common/ToggleButton';
import { Icon } from '../Icons/Icon';
import { usePhoneIntl } from 'hooks/usePhoneIntl';
import { useSharedSettings } from 'hooks/useSharedSettings';
import type { Fields } from 'api/getCompanySignupInfo/signup.types';
import type { UserData } from 'api/UserPreferencesUpdate/user.types';

import styles from './SignUp.module.scss';

export interface StepFormProps {
	defaultValues: Partial<UserData>;
	fields: Fields;
	image?: string;
	onSubmit: (data: UserData) => void;
	title?: string;
	subtitle?: string;
	submitLabel: string;
	children?: ReactNode;
}

export const StepForm = ({
	defaultValues,
	fields,
	image,
	onSubmit,
	children,
	title,
	subtitle,
	submitLabel,
}: StepFormProps) => {
	const { t } = useTranslation();
	const {
		control,
		formState: { errors, isValidating, isSubmitting },
		getValues,
		handleSubmit,
		register,
		setValue,
	} = useForm<UserData>({
		defaultValues,
		mode: 'onBlur',
	});

	const [composeRef, options] = usePhoneIntl();
	const { ref, ...phoneProps } = register('phone', {
		required: fields?.phone?.required,
		...options,
	});
	const { timezones } = useSharedSettings();
	const timezonesByContinent = useMemo(
		() =>
			timezones?.reduce<Record<string, OptionProps[]>>(
				(continents, { time_zone, offset }) => {
					const [continent, country_or_timezone, timezone] =
						time_zone.split(' / ');
					return Object.assign(continents, {
						[continent]: [
							...(continents[continent] || []),
							{
								value: time_zone,
								label: `${
									timezone || country_or_timezone
								} (GMT${offset >= 0 ? '+' : ''}${offset})`,
							},
						],
					});
				},
				{}
			) || {},
		[timezones]
	);

	// HACK : Send Resize Event so that the Switch component will re-analyze the size of this element and adjust the window accordingly.
	window.dispatchEvent(new Event('resize'));

	useEffect(() => {
		// set form value once timezones are loaded
		if (timezones) setValue('timezone', getValues().timezone);
	}, [timezones, getValues, setValue]);

	return (
		<>
			<Form
				onSubmit={handleSubmit(onSubmit)}
				aria-label={submitLabel}
				className={styles.Form}
				data-name="signup-step-3"
			>
				<div className={styles.CardContent}>
					{title && <h1>{title}</h1>}
					{subtitle && <h2>{subtitle}</h2>}
					{image && (
						<img
							src={image}
							alt="form"
							className={styles.StepImage}
						/>
					)}
					{fields?.firstName?.shown ? (
						<FormField
							label={t('first_name')}
							placeholder="Emmeline"
							autoComplete="given-name"
							{...register('firstName', {
								required: true,
							})}
							error={!!errors.firstName}
							errorText={
								errors.firstName &&
								t(
									[
										errors.firstName.message ||
											'form_firstName_error',
										'form_firstName_error',
									],
									{
										email: process.env
											.REACT_APP_FEEDBACK_EMAIL,
									}
								)
							}
						></FormField>
					) : null}
					{fields?.lastName?.shown ? (
						<FormField
							label={t('last_name')}
							placeholder="Pankhurst"
							autoComplete="family-name"
							{...register('lastName', {
								required: fields.lastName.required,
							})}
							error={!!errors.lastName}
							errorText={
								errors.lastName &&
								t(
									[
										errors.lastName.message ||
											'form_lastName_error',
										'form_lastName_error',
									],
									{
										email: process.env
											.REACT_APP_FEEDBACK_EMAIL,
									}
								)
							}
						/>
					) : null}
					{/* Gateway selection is disabled until backend is ready to receive this as a registration parameter */}
					{fields?.gateway?.shown ? (
						<>
							<legend>{t('preferences_choose_gateway')}</legend>
							<FormToggleButton
								name="gateway"
								control={control}
								options={fields.gateway.options?.map(
									(option) => {
										return {
											value: option,
											label: option.split('-')[0],
										};
									}
								)}
							/>
						</>
					) : null}
					{fields?.email?.shown ? (
						<FormField
							label={t('email_')}
							type="email"
							placeholder="me@company.com"
							autoComplete="email"
							{...register('email', {
								required: fields.email.required,
							})}
							error={!!errors.email}
							errorText={
								errors.email &&
								t(
									[
										errors.email.message ||
											'form_email_error',
										'form_email_error',
									],
									{
										email: process.env
											.REACT_APP_FEEDBACK_EMAIL,
									}
								)
							}
						/>
					) : null}
					{fields?.phone?.shown ? (
						<FormPhoneField
							label={t('phone_number')}
							ref={composeRef(ref)}
							{...phoneProps}
							loading={isValidating}
							error={!!errors.phone}
							className={styles.PhoneField}
							errorText={
								errors.phone &&
								t(
									[
										errors.phone.message ||
											'form_telephone_error',
										'form_telephone_error',
									],
									{
										email: process.env
											.REACT_APP_FEEDBACK_EMAIL,
									}
								)
							}
						/>
					) : null}
					{fields?.timezone?.shown ? (
						<>
							<legend>
								{t('preferences_choose_timezone_description')}
							</legend>
							<FormSelect
								label={t('timezone_')}
								options={timezonesByContinent}
								{...register('timezone', {
									required: fields.timezone.required,
								})}
								error={!!errors.timezone}
							/>
						</>
					) : null}
					{fields?.language?.shown ? (
						<>
							<legend>
								{t('preferences_choose_language_description')}
							</legend>
							<FormToggleButton
								name="language"
								control={control}
								options={[
									{
										value: 'FR',
										label: (
											<>
												{t('french_')}{' '}
												<Icon name="flagFR" />
											</>
										),
									},
									{
										value: 'EN',
										label: (
											<>
												<Icon name="flagEN" />{' '}
												{t('english_')}
											</>
										),
									},
								]}
							/>
						</>
					) : null}
					<CtaButton
						disabled={isSubmitting}
						pulse={isSubmitting}
						id="submit"
						data-name="submit"
					>
						{submitLabel}
					</CtaButton>
					{children}
				</div>
			</Form>
		</>
	);
};
