import * as Sentry from '@sentry/react';

export const checkFetchError = async (response) => {
	const body = await response.text();
	try {
		const data = JSON.parse(body);
		if (response.status !== 200 || data.status !== 'OK' || data.error) {
			// I'm only adding the breadcrumbs for failed requests, because I dont' want the error to exceed the 100KB Sentry payload limit
			let responseText = JSON.stringify(data);
			console.warn(
				'Invalid response from server!',
				...response.headers,
				responseText
			);
			Sentry.addBreadcrumb({
				category: 'xhr-headers',
				data: responseText.slice(0, 500),
				level: 'Error',
			});
			throw new Error(data.error);
		} else return data;
	} catch (e) {
		console.error(
			'Invalid response from server:',
			body,
			...response.headers
		);
		throw e;
	}
};
