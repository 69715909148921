import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gv_analytics } from 'utils/Analytics';
import { gvShareMethods as share } from 'utils/utils';
import { useGlobalSettings } from 'hooks/useGlobalSettings';
import styles from './UnsubscribeCard.module.scss';

import { Card, CtaButton } from 'components';

export const UnsubscribeCard = ({
	id = 'unsubscribe',
	back,
	userID,
	userToken,
}) => {
	const { t } = useTranslation();
	const {
		contactEmail,
		video: { name },
		userData: { unsubToken },
	} = useGlobalSettings();

	// Send feedback to editorial team
	const sendFeedbackEmailURL = share.get.email_href({
		email: contactEmail,
		title: t('feedback_message_subject', { name }),
		text: {
			long: t('feedback_message_body', { name }),
		},
	});
	const [subscribed, setSubscribed] = useState(true);

	const [ctaString, setCtaString] = useState(t('unsub_confirm'));
	const performUnsubscribe = (userID) => {
		// console.log('Unsubscribing!', userID);
		gv_analytics.capture('USER_CLICKED_TO_UNSUBSCRIBE');
		setCtaString(t('unsub_in_progress')); // @TODO This should be animated
		fetch(`/api/UserUnsubscribe`, {
			method: 'POST',
			body: JSON.stringify({ id: btoa(userID), unsubToken }),
		})
			.then((response) => {
				gv_analytics.capture('USER_UNSUBSCRIBE_SUCCESSFUL');
				const { status } = response;
				// console.log(response, status, statusText);
				if (status < 299) setSubscribed(false);
				else setCtaString(t('unsub_failed'));
			})
			.catch((err) => {
				gv_analytics.capture('USER_UNSUBSCRIBE_FAILED');
				setCtaString(t('unsub_failed'));
				console.error(err);
			});
	};

	return (
		<Card id={id} data-name="UnsubscribeCard" className={styles.UnsubCard}>
			{/* <Markdown className={styles.Content}>{text || ''}</Markdown> */}
			<div className={styles.UnsubContainer}>
				<h1 className={styles.Title}>{t('unsub_card_title')}</h1>
				<h2>{t('unsub_card_subtitle')}</h2>
				<div className={styles.UnsubParagraph}>
					<p>{t('unsub_card_paragraph_1')}</p>
					{/* <br />
					<br /> */}
					<a
						href={sendFeedbackEmailURL}
						onClick={() =>
							gv_analytics.capture('USER_SEND_FEEDBACK')
						}
					>
						{t('unsub_card_paragraph_2')}
					</a>
				</div>
				{/* <textarea defaultValue={"(Optional)"}/> @TODO later */}
				{subscribed ? (
					<CtaButton onClick={() => performUnsubscribe(userID)}>
						{ctaString}
					</CtaButton>
				) : (
					<h2>{t('unsub_confirmed')}</h2>
				)}
				{
					subscribed ? (
						<CtaButton onClick={back} variant="secondary">
							{t('unsub_back')}
						</CtaButton>
					) : null
					// Can't currently close windows that you didn't create programatically
					// <CtaButton onClick={() => window.close()} variant="secondary">
					// 	{t('close_window')}
					// </CtaButton>
				}
			</div>
		</Card>
	);
};
