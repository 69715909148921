import Markdown from 'markdown-to-jsx';

import { Card, SocialIcon } from 'components';

import styles from './BiographyCard.module.scss';

export const BiographyCard = ({
	id,
	bio_photo,
	fullName,
	company,
	company_logo,
	linkedin_username,
	twitter_handle,
	website,
	blog,
	title,
	biography,
}) => (
	<Card id={id} className={styles.BiographyCard} data-name="BiographyCard">
		<div className={styles.PersonInfo}>
			{bio_photo && (
				<img alt={fullName} className={styles.Avatar} src={bio_photo} />
			)}
			<p className={styles.Name}>{fullName}</p>
			<p className={styles.Title}>{title}</p>
			<p className={styles.Company}>
				{company_logo ? <img src={company_logo} alt={company} /> : company}
			</p>
		</div>
		<div>
			<Markdown className={styles.Content}>{biography || ''}</Markdown>
			<span className={styles.SocialProfiles}>
				{website && (
					<a href={website} target="_blank" rel="noopener noreferrer">
						<SocialIcon name="website" />
					</a>
				)}
				{linkedin_username && (
					<a
						href={`https://www.linkedin.com/in/${linkedin_username}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<SocialIcon name="linkedin" />
					</a>
				)}
				{twitter_handle && (
					<a
						href={`https://twitter.com/${twitter_handle}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<SocialIcon name="twitter" />
					</a>
				)}
				{blog && (
					<a href={blog} target="_blank" rel="noopener noreferrer">
						<SocialIcon name="blog" />
					</a>
				)}
				{/*             
        <a href="#facebook" target="_blank" rel="noopener noreferrer">
          <SocialIcon name="facebook" />
        </a>
        <a href="#instagram" target="_blank" rel="noopener noreferrer">
          <SocialIcon name="instagram" />
        </a>
        <a href="#youtube" target="_blank" rel="noopener noreferrer">
          <SocialIcon name="youtube" />
        </a>
        <a href="#github" target="_blank" rel="noopener noreferrer">
          <SocialIcon name="github" />
        </a>
        */}
			</span>
		</div>
	</Card>
);
