// import { useTranslation, Trans } from 'react-i18next'
import * as Sentry from '@sentry/react';
import { useState, useEffect, useMemo, useRef } from 'react';
import { VoteQuestion } from './VoteQuestion';
import { createVoteButtons } from './createVoteButtons.module';
import { logVote } from 'utils/backend';
import styles from './Voting.module.scss';
import { useGlobalSettings } from 'hooks/useGlobalSettings';
import { gv_analytics } from 'utils/Analytics';

export const VoteInterface = ({
	show,
	muted,
	resumeVideo,
	votePrompt,
	voteAnswers,
	voteDuration,
	showAnswersDelay,
	showNeutralAnswerDelay,
	defaultAnswer,
	timerFX_URL,
	celebrationFX_URL,
	backgroundImage = false,
}) => {
	const [curVote, setCurVote] = useState(null);
	const [voteStartTime, setVoteStartTime] = useState(null);

	// Needed to assist with re-displaying the voting UI if the user rewinds and re-plays the vote sequence
	const [userHasVotedThisSession, setUserHasVotedThisSession] =
		useState(false);
	const GLOBAL_SETTINGS = useGlobalSettings();
	const votingTimeout = useRef(null);

	const timerFX = useMemo(() => {
		let fx = new Audio(timerFX_URL);
		fx.volume = 0.0;
		fx.loop = true;
		fx.preload = 'auto';
		return fx;
	}, [timerFX_URL]);

	const celebrationFX = useMemo(() => {
		let fx = new Audio(celebrationFX_URL);
		fx.volume = 0.0;
		fx.loop = false;
		fx.preload = 'auto';
		return fx;
	}, [celebrationFX_URL]);

	const playSound = (sound, volume = 0.2) => {
		sound.currentTime = 0;
		sound.volume = volume;
		sound.play().catch((e) => {
			console.warn(
				`Could not play audio, has user interacted with the page?`,
				sound.src
			);
			Sentry.captureException(e);
		});
	};
	/*
	 * THIS IS TRIGGERED WHEN A USER VOTES
	 * Pauses up timer SFX + sets Vote + Plays celebration SFX
	 */
	const processVote = (vote) => {
		timerFX.pause();
		if (!muted) playSound(celebrationFX, 0.4);
		setUserHasVotedThisSession(true);
		setCurVote(vote);
	};

	const unlockAudio = (e) => {
		// timerFX.load();
		// timerFX.play();
		// timerFX.pause();
		// celebrationFX.load();
		// celebrationFX.play();
		// celebrationFX.pause();
		document.body.removeEventListener('touchstart', unlockAudio);
	};

	document.body.addEventListener('touchstart', unlockAudio);

	/* SETUP + PLAY BG TIMER FX */
	useEffect(() => {
		if (!show) return;
		setUserHasVotedThisSession(false);
		votingTimeout.current = setTimeout(() => {
			console.log('Voting Timed out!');
			setCurVote(0);
			gv_analytics.capture('VOTE_TIMEOUT');
		}, voteDuration * 1000);
		setVoteStartTime(new Date().getTime());

		if (!muted) playSound(timerFX, 0.2);

		return function cleanup() {
			clearTimeout(votingTimeout.current);
			timerFX.pause();
		};
	}, [muted, show, timerFX, voteDuration, votingTimeout]);

	/**
	 * voting useEffect
	 *
	 * */
	useEffect(() => {
		if (userHasVotedThisSession === false || curVote === null) return;
		try {
			const timeToVote = new Date().getTime() - voteStartTime;
			clearTimeout(votingTimeout.current);
			Sentry.addBreadcrumb({
				category: 'info',
				message: `User voted for option ${curVote} in ${timeToVote}ms`,
				level: 'Info',
			});
			resumeVideo();
			/**
			 * IMPORTANT:
			 * curVote starts at 0 for timeout/default answer;
			 * in order to log the correct vote in db, we need to set voteAnswers to curVote-1
			 */
			logVote(
				GLOBAL_SETTINGS,
				curVote,
				voteAnswers[curVote - 1],
				timeToVote
			);
			gv_analytics.capture('USER_VOTED', {
				vote: curVote,
				answer: voteAnswers[curVote - 1],
				duration: timeToVote,
			});
		} catch (e) {
			console.warn(
				'Could not play audio, has user interacted with the page?'
			);
		}
	}, [
		GLOBAL_SETTINGS,
		curVote,
		resumeVideo,
		userHasVotedThisSession,
		voteAnswers,
		voteStartTime,
		votingTimeout,
	]);

	const defaultAnswerFlexOrder = 5;

	/* important architectural detail! -> 
	if Voting is enabled, VideoPlayer will render VotingInterface at all times. 
	This is so that the logic contained in VotingInterface (like keeping track of the user's previous vote) and other elements such as the sound effects, remain in-memory. 

	At the appropriate time in the video, VotingInterface will return the DOM elements, 
	thus 'rendering' it to the screen. */

	return show ? (
		<div
			data-name="VoteContainer"
			className={styles.VoteContainer}
			style={
				backgroundImage
					? {
							// Background image should only be used in Storybook
							backgroundImage: `url(${backgroundImage})`,
							backgroundSize: 'contain',
							position: 'relative',
					  }
					: {}
			}
		>
			<VoteQuestion
				text={votePrompt}
				numAnswers={voteAnswers.filter((x) => x).length}
			/>
			<div data-name="AnswerContainer" className={styles.AnswerContainer}>
				{/* DEFAULT ANSWER: Is technically higher in the DOM, but setting the flex order shows it at the bottom. */}
				{createVoteButtons(
					[defaultAnswer],
					curVote,
					processVote,
					showNeutralAnswerDelay,
					defaultAnswerFlexOrder
				)}
				{/* Add a blank element in the beginning so the index for the first 'real' vote starts at 1 */}
				{createVoteButtons(
					['', ...voteAnswers],
					curVote,
					processVote,
					showAnswersDelay
				)}
			</div>
		</div>
	) : null;
};
