import Slider from 'infinite-react-carousel';

import { useWindowWidth } from 'hooks/useWindowWidth';

import styles from './CarouselCard.module.scss';

export const CarouselCard = ({ id, children }) => {
	const windowWidth = useWindowWidth();
	const slidesToShow = Math.min(3, Math.floor(windowWidth / 375)) || 1;

	return (
		<article id={id} className={styles.CarouselCard}>
			<Slider
				dots
				arrows
				centerMode
				adaptiveHeight
				overScan={0}
				slidesToShow={slidesToShow}
			>
				{children}
			</Slider>
		</article>
	);
};
