import React from 'react';
import { Card } from 'components';
import { cls } from '../../utils/cls';
import styles from './Stepper.module.scss';

export const Step = ({ number, legend, active = false, current }) => {
	return (
		<div className={styles.SingleStep} data-name={`step-${number}`}>
			<StepNumber current={current} active={active}>
				{number}
			</StepNumber>
			<span
				className={cls(
					styles.Legend,
					active && styles.Active,
					current && styles.Current
				)}
			>
				{legend.toUpperCase()}
			</span>
		</div>
	);
};

export const StepNumber = ({ active = null, current = 0, children }) => {
	return (
		<span
			className={cls(
				styles.NumberContainer,
				active && styles.Active,
				current && styles.Current
			)}
		>
			<span className={styles.Number}>{children}</span>
		</span>
	);
};

export const Stepper = ({ steps, currentStep }) => {
	return (
		<div className={styles.Stepper} data-name="stepper">
			{steps.map((step, index) => {
				const isNotLastStep = index + 1 < steps.length;
				return (
					<React.Fragment key={index}>
						<Step
							number={index + 1}
							legend={step}
							active={currentStep > index}
							current={currentStep === index}
						/>
						{isNotLastStep && <div className={styles.Line}></div>}
					</React.Fragment>
				);
			})}
		</div>
	);
};

export const StepperCard = ({
	id = null,
	currentStep = 0,
	steps,
	className = null,
	name = 'stepper-card',
}) => (
	<Card
		id={id}
		className={cls(styles.StepperCard, className)}
		data-name={name}
		header={false} // Disable default header
	>
		<Stepper
			steps={steps.map(({ title }) => title)}
			currentStep={currentStep}
		/>
		<div className={styles.StepContentContainer}>
			{steps[currentStep]?.content()}
		</div>
	</Card>
);
