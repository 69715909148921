import { cls } from '../../utils/cls';
import styles from './Voting.module.scss';
import Markdown from 'markdown-to-jsx';

export const VoteButton = ({
	dataVote,
	currentVote,
	updater,
	variant = 'primary',
	children,
	className,
	type,
}) => {
	return (
		<button
			type={type}
			className={cls(
				className,
				currentVote === dataVote ? styles.Clicked : '',
				styles.VoteButton,
				variant === 'secondary' && styles.Secondary
			)}
			onClick={() => updater(dataVote)}
			data-vote={dataVote}
			style={{ pointerEvents: 'inherit' }}
		>
			<Markdown>{children}</Markdown>
		</button>
	);
};
