import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CtaButton } from 'components';
import { delay } from '../../utils/delay';
import styles from './Preferences.module.scss';

interface RequestCodeProps {
	sendCode: () => Promise<void>;
	children: JSX.Element;
}
export const RequestCode = ({ sendCode, children }: RequestCodeProps) => {
	const { t } = useTranslation();
	const [disabled, setDisabled] = useState(false);
	const [requesting, setRequesting] = useState(false);
	const requestCode = async () => {
		try {
			setRequesting(true);
			setDisabled(true);
			await sendCode();
		} catch (error) {
			console.error(error);
			setRequesting(false);
			await delay(5000); // 5 seconds
		}
		setDisabled(false);
	};

	return (
		<Card id="preferences" className={styles.PreferencesCard}>
			<div className={styles.RequestCodeContainer}>
				<h1>{t('request_secure_code_title')}</h1>
				{t('request_secure_code_subtitle')}
				<div style={{}} className={styles.OrderedList}>
					<div className={styles.RoundedNumber}>
						<h4 style={{ display: 'inline' }}>
							{t('request_secure_code_step_1')}
						</h4>
					</div>
					<div className={styles.RoundedNumber}>
						<h4 style={{ display: 'inline' }}>
							{t('request_secure_code_step_2')}
						</h4>
					</div>
					<div className={styles.RoundedNumber}>
						<h4 style={{ display: 'inline' }}>
							{t('request_secure_code_step_3')}
						</h4>
					</div>
				</div>
				<CtaButton
					disabled={disabled}
					data-name={'request_secure_code_cta'}
					title={
						disabled
							? t('requesting_secure_code_cta_disabled')
							: undefined
					}
					onClick={requestCode}
				>
					{requesting
						? t('request_secure_code_loading')
						: t('request_secure_code_cta')}
				</CtaButton>
				{children}
			</div>
		</Card>
	);
};
