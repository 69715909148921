import Markdown from 'markdown-to-jsx';

import { Card } from 'components';

import styles from './QuoteCard.module.scss';
import { useTranslation } from 'react-i18next';

export const QuoteCard = ({ id, quote_text, author_name, title, type }) => {
	const { t } = useTranslation();

	return (
		<Card id={id} data-name="QuoteCard" className={styles[type]}>
			<div className={styles.Container}>
				<Markdown
					options={{ wrapper: 'blockquote' }}
					className={styles.Text}
				>
					{type === 'TipCard'
						? quote_text
						: `${t('guillemet_open')} ${quote_text} ${t(
								'guillemet_close'
						  )}` || ''}
				</Markdown>
				<hr className={styles.Separator} />
				<p className={styles.QuoteSource}>
					<strong>{author_name}</strong>
					{title}
				</p>
				{/* <SocialSharing /> */}
			</div>
		</Card>
	);
};
