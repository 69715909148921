import { useEffect, useState } from 'react';

import { usePlayer, usePlayerUpdates } from 'kaltura-player';
import { Icon } from 'components/Icons/Icon';

export const ControlPlayPause = ({ playerId, size }) => {
	const { getPlayerInstance } = usePlayer(playerId);
	const { playerState$ } = usePlayerUpdates(playerId);
	const [paused, setPaused] = useState(false);
	const playerInstance = getPlayerInstance();

	useEffect(() => {
		if (!playerInstance) return;
		const stateSubscription = playerState$.subscribe((state) => {
			setPaused(state === 'paused' || state === 'idle');
		});
		return () => stateSubscription.unsubscribe();
	});

	const toggle = () => {
		if (!playerInstance) return;
		if (paused) playerInstance.play();
		else playerInstance.pause();
	};

	return (
		<Icon
			name={paused ? 'play' : 'pause'}
			size={size || 35}
			onClick={toggle}
			data-name="ControlPlayPause"
		/>
	);
};
