import { useEffect, useState } from 'react';
import posthog, { CaptureResult } from 'posthog-js';
import * as Sentry from '@sentry/react';
import { Session } from 'api/getVideoFromCode/session.types';

const captureDebugObjects: {
	$posthogCaptures: CaptureResult[];
} = {
	$posthogCaptures: [],
};

export const gv_analytics = {
	capture: (event: string, set?: object, set_once?: object) => {
		try {
			posthog.capture(event, set, set_once);
		} catch (e) {
			console.error('Error capturing analytics event:', e);
		}
	},
	addContext: (context: [object]) => {
		try {
			for (let key in context) {
				Sentry.setContext(key, context[key]);
			}
		} catch (e) {
			console.error('Error capturing analytics context:', e);
		}
	},
	...captureDebugObjects,
};
// Make posthog and gv_analytics methods available globally for debugging
(window as { [key: string]: any })['posthog'] = posthog;
(window as { [key: string]: any })['gv_analytics'] = gv_analytics;

export const applyUserCookies = (session: Session) => {
	var cookiesToStore = session.userData?.cookies?.split(',') || [];
	var expiration_date = new Date(
		new Date().getTime() + 1000 * 60 * 60 * 24 * 30 * 13
	); // 13 months
	for (let cookie in cookiesToStore) {
		document.cookie =
			cookiesToStore[cookie] +
			'=' +
			new Date().toISOString() +
			`; Domain=${window.location.host}; Path=/; Expires=` +
			expiration_date.toUTCString();
	}
};

interface PlayerSessionProps {
	[index: string]: any;
	videoID: string;
	videoName: string;
	language: string;
}

interface SignupSessionProps {
	[index: string]: any;
	signupID: string;
	language: string;
}

interface userPropsType {
	userID?: number;
	companyID?: number;
	companyName?: string;
	companyInternalUID?: string;
	companyInternalGroup?: string;
}

export interface TrackerProps {
	app_name: string;
	brand_name: string | undefined;
	userProps: userPropsType;
	sessionProps: PlayerSessionProps | SignupSessionProps;
	disable_analytics: boolean;
	from_shared_link: boolean;
}

export const Analytics = ({
	app_name,
	userProps,
	sessionProps,
	disable_analytics,
	from_shared_link,
}: TrackerProps) => {
	//
	//   _____    _            _   _  __
	//  |_   _|  | |          | | (_)/ _|
	//    | |  __| | ___ _ __ | |_ _| |_ _   _
	//    | | / _` |/ _ \ '_ \| __| |  _| | | |
	//   _| || (_| |  __/ | | | |_| | | | |_| |
	//  |_____\__,_|\___|_| |_|\__|_|_|  \__, |
	//                                    __/ |
	//                                   |___/

	const [PhLoaded, setPhLoaded] = useState(false);
	useEffect(() => {
		// console.log('Identify called', PhLoaded, userProps, from_shared_link);
		if (!PhLoaded) return;
		// We DONT want to identify users in Posthog / GA if it's from a shared link
		// posthog will automatically create an anonymous ID for them,
		// and if they end up signing up, we'll identify them correctly via their own link :)

		if (from_shared_link) {
			// GUEST VISITING A SHARED LINK
			// TODO - SHould this be handled outside this tracking module? Seems like perhaps the analytics properties should be figured out 'above' it, and passed into the analytics module.
			let referralInfo = {
				referredByUserID: userProps.userID,
				referredByCompanyID: userProps.companyID,
				referredByCompanyName: userProps.companyName,
				referredByVideoID: sessionProps.videoID,
				referredByVideoName: sessionProps.videoName,
				referredByGroupID: userProps.companyInternalGroup,
				referredBycompanyInternalUID: userProps.companyInternalUID,
			};
			posthog.capture('SHARED_LINK_IDENTIFIED', {
				$set_once: referralInfo,
			});
			Sentry.setUser({
				ip_address: '{{auto}}',
			});
		} else if (userProps && userProps.userID && userProps.userID !== -1) {
			// IS A SPECIFIC USER
			// console.log(119, 'PH SETTING USER PROPS', userProps);
			Sentry.setUser({
				id: userProps.userID.toString(),
				ip_address: '{{auto}}',
				companyInternalGroup: userProps.companyInternalGroup,
				companyInternalUID: userProps.companyInternalUID,
			});
			// posthog.people.set(userProps);
			posthog.identify(userProps.userID.toString(), userProps);
		} else {
			// NOT A SPECIFIC USER
			posthog.people.set({
				companyID: userProps.companyID,
				companyName: userProps.companyName,
			});
			Sentry.setUser({
				ip_address: '{{auto}}',
			});
		}
		posthog.people.set_once({
			// set initial values against the person
			initial_companyID: userProps.companyID,
			initial_companyName: userProps.companyName,
			initial_videoID: sessionProps.videoID,
		});
		// console.log('Analytics identify complete!');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [PhLoaded, from_shared_link, userProps, ...Object.values(userProps)]);

	// Using PhLoaded as a dependency effectively delays calling this until Posthog is loaded, and also allows us to 'update' it if anything changes.

	// Initialize analytics and error tracking
	//   _____       _ _   _       _ _
	//  |_   _|     (_) | (_)     | (_)
	//    | |  _ __  _| |_ _  __ _| |_ _______
	//    | | | '_ \| | __| |/ _` | | |_  / _ \
	//   _| |_| | | | | |_| | (_| | | |/ /  __/
	//  |_____|_| |_|_|\__|_|\__,_|_|_/___\___|

	useEffect(() => {
		try {
			// console.log(
			// 	125,
			// 	'Init called',
			// 	app_name,
			// 	disable_analytics,
			// 	process.env.REACT_APP_PLAYER_PH_TOKEN,
			// 	process.env.REACT_APP_PH_INSTANCE_URL,
			// 	process.env.NODE_ENV
			// );
			posthog.init(process.env.REACT_APP_PLAYER_PH_TOKEN ?? '', {
				// api_host: process.env.REACT_APP_PH_INSTANCE_URL,
				api_host: window.location.origin + '/quality',
				// disable_persistence: true,
				opt_out_capturing_by_default: true,
				persistence: 'memory', // Due to some users getting mixed up by being subscribed to our internal VIP list AND their own company thing, we don't want to mix users up. either they use a user-specific link, or they use the generic one, tracking them across both is not that important.
				autocapture:
					process.env.REACT_APP_PH_AUTOCAPTURE_ENABLED === 'true',
				disable_session_recording:
					process.env.REACT_APP_PH_SESSION_RECORDING_ENABLED !==
					'true',
				debug: process.env.NODE_ENV !== 'production',
				_onCapture: (event, eventData) => {
					gv_analytics.$posthogCaptures.push(eventData);
				},
				loaded: (posthog) => {
					// alert("ph loaded script running")
					posthog.register({
						app_name,
						brand_name: process.env.REACT_APP_BRAND_NAME,
						from_shared_link,
						branch: process.env.BRANCH,
						dark_mode:
							window.matchMedia &&
							window.matchMedia('(prefers-color-scheme: dark)')
								.matches
								? 'UI_DARK'
								: 'UI_LIGHT',
					});
					if (disable_analytics) {
						posthog.opt_out_capturing();
					} else posthog.opt_in_capturing();
					posthog._start_queue_if_opted_in(); // See https://github.com/PostHog/posthog-js/issues/336
					setPhLoaded(true);
					console.log('Analytics loaded!');
				},
			});
			console.log('Analytics initialized');
		} catch (e) {
			console.error('Could not initialize Analytics', e);
		}
	}, [app_name, disable_analytics, from_shared_link]);

	// Session Props (properties that apply only to events sent, not the user)
	//    _____               _               _____
	//   / ____|             (_)             |  __ \
	//  | (___   ___  ___ ___ _  ___  _ __   | |__) | __ ___  _ __  ___
	//   \___ \ / _ \/ __/ __| |/ _ \| '_ \  |  ___/ '__/ _ \| '_ \/ __|
	//   ____) |  __/\__ \__ \ | (_) | | | | | |   | | | (_) | |_) \__ \
	//  |_____/ \___||___/___/_|\___/|_| |_| |_|   |_|  \___/| .__/|___/
	//                                                       | |
	//                                                       |_|
	function registerProps(obj: any): void {
		for (let key in obj) {
			let registrationObject = { [key]: obj[key] };
			// console.log('Registering property:', registrationObject);
			posthog.register(registrationObject);
			Sentry.setTag(key, obj[key]);
		}
	}
	useEffect(() => {
		if (!PhLoaded) return;
		// console.log("Registering new props!")
		registerProps({ ...sessionProps, branch: process.env.branch });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [PhLoaded, ...Object.values({ ...sessionProps })]);

	return null;
};
