export const getDefaultLanguage = () =>
	(
		navigator.languages?.[0] ||
		(navigator as any).userLanguage ||
		navigator.language ||
		(navigator as any).browserLanguage ||
		'EN'
	)
		.slice(0, 2)
		.toUpperCase();
