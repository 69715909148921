import React from 'react';
import { useUiSettings } from 'hooks/useGlobalSettings';

import {
	ControlPlayPause,
	ControlFullScreen,
	ControlClap,
	ControlMute,
	ControlRewind,
	Icon,
} from 'components';
import { controlBar, disabled } from './ControlBar.module.scss';

const iconSize = '3.5vh';

export const ControlBar = ({ playerId, isVoting }) => {
	const { show_clap, show_fullscreen } = useUiSettings();

	return (
		<div
			className={[controlBar, isVoting ? disabled : null].join(' ')}
			data-name="ControlBar"
		>
			<ControlPlayPause
				size={iconSize}
				playerId={playerId}
				data-name="ControlPlayPause"
			/>
			<ControlMute
				size={iconSize}
				playerId={playerId}
				data-name="ControlMute"
			/>
			{show_clap ? (
				<ControlClap
					size={iconSize}
					playerId={playerId}
					data-name="ControlClap"
				/>
			) : null}
			{!show_fullscreen && show_clap && (
				/* Create blank space on the left of rewind button by Icon component if show_fullscreen is not enabled */
				<Icon name="" />
			)}
			<ControlRewind
				size={iconSize}
				playerId={playerId}
				data-name="ControlRewind"
			/>
			{show_fullscreen ? (
				<ControlFullScreen
					size={iconSize}
					playerId={playerId}
					data-name="ControlFullScreen"
				/>
			) : null}
		</div>
	);
};
