import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Toaster } from 'react-hot-toast';

import logo from '../../static/img/logo.svg';
import { useUiSettings } from 'hooks/useGlobalSettings';
import styles from './Page.module.scss';

export const Page = ({ children, style = {} }) => {
	const { set_bg_color } = useUiSettings();

	return (
		<section
			style={
				set_bg_color
					? {
							backgroundImage: 'unset',
							backgroundColor: set_bg_color,
							...style,
					  }
					: style
			}
			className={styles.Page}
			data-name="Page"
		>
			{children}
			<Toaster
				position="bottom-center"
				toastOptions={{
					success: {
						duration: 4000,
					},
					error: {
						duration: 8000,
					},
				}}
			/>
		</section>
	);
};

/**
 * Header only displayed if uiParams show_header is true */
export const PageHeader = ({ children }) => {
	const { show_header } = useUiSettings();

	return show_header ? (
		<header className={styles.Header} data-name="PlayerPageHeader">
			<ReactSVG src={logo} className={styles.Logo} />
			{children}
		</header>
	) : (
		<div />
	);
};

/**
 * Footer only displayed if uiParams show_footer is true */
export const PageFooter = ({ children, cta = null }) => {
	const { show_footer } = useUiSettings();
	const { t } = useTranslation();

	return show_footer ? (
		<footer className={styles.Footer} data-name="PlayerPageFooter">
			<div className={styles.FooterLinks}>{children}</div>
			<div className={styles.MadeWithAndCopyright}>
				<div onClick={cta}>{t('made_with_love_in_france')}</div>
				<div>{t('all_rights_reserved')}</div>
			</div>
		</footer>
	) : (
		<div />
	);
};
