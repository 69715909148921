import { useState } from 'react';

import { usePlayer } from 'kaltura-player';
import { gv_analytics } from 'utils/Analytics';
import { Icon } from 'components/Icons/Icon';

export const ControlMute = ({ playerId, size }) => {
	const { getPlayerInstance } = usePlayer(playerId);
	const [muted, setMuted] = useState(false);
	const toggleMute = () => {
		const playerInstance = getPlayerInstance();
		if (!playerInstance) return;
		playerInstance.muted = !playerInstance.muted;
		setMuted(playerInstance.muted);
		gv_analytics.capture(
			playerInstance.muted ? 'USER_MUTED' : 'USER_UNMUTED'
		);
	};

	return (
		<Icon
			name={muted ? 'soundon' : 'soundoff'}
			size={size || 35}
			onClick={toggleMute}
			data-name="ControlMute"
		/>
	);
};
