import { useCallback, useEffect, useState } from 'react';
import type { Timezone } from 'api/UserPreferences/preferences.types';

import { getDefaultLanguage } from 'utils/getDefaultLanguage';

const apiUrl = process.env.REACT_APP_API_URL;

const defaultLanguage = getDefaultLanguage();

interface SharedSettings {
	timezones: Timezone[];
}

export const useSharedSettings = (language = defaultLanguage) => {
	const [sharedSettings, setSharedSettings] = useState<
		Partial<SharedSettings>
	>({});
	const getSharedSettings = useCallback(async () => {
		const response = await fetch(
			`${apiUrl}/User/GetGlobalSettings?language=${language}`
		);
		return response.json() as Promise<SharedSettings>;
	}, [language]);
	useEffect(() => {
		getSharedSettings().then(setSharedSettings);
	}, [getSharedSettings]);

	return sharedSettings;
};
